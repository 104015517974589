var hostForAPICalls = location.hostname;
hostForAPICalls = (location.hostname === 'reg.consentic.com' ? "https://app.consentic.com" : "https://cert.consentic.com");
const apiCall = require('axios').create({ withCredentials: true, 
  headers: { 'Cache-Control': 'no-cache' },
  baseURL: hostForAPICalls});
  
  const getDefaultState = () => {
    return {
      consentRequest: {},
      consentTypes: [],
      consentPDF: '',
      bFetchingData: false,
    }
  }
  
  const state = getDefaultState();
  
  const getters = {
    getConsentRequest: (state) => { return state.consentRequest },
    getConsentTypes: (state) => { return state.consentTypes },
    getConsentPDF: (state) => { return state.consentPDF },
  };
  
  const actions = {
    // eslint-disable-next-line
    async fetchConsentRequest({ commit }, formData) {
      apiCall.get(hostForAPICalls + `/api/v1/p/consent-request/${formData.id}?signature=${formData.signature}`,
        {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}})
      .then(response => {
        console.warn("ConsentRequest info:",response.data.data);
        commit('setConsentRequest', response.data.data);
        commit('setConsentTypes',response.data.types);
        state.bFetchingData=false;
      })
      .catch(error => {
        console.log("Error fetching patient:",error);
        state.bFetchingData=false;
      });
    },
    // eslint-disable-next-line
    async updateConsentRequest({ commit }, formData) {      

      formData.question = JSON.stringify(formData.questions)

      const form = {
        ...formData,
        '_method': 'put'
      };
      
      const response = await apiCall.post(`${hostForAPICalls}/api/v1/p/consent-request/${formData.id}?signature=${formData.signature}`, form,
        { headers: 
          { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
          } 
        }
      );

      if (response.status === 200) {
        // 
      } else {
        throw new Error(JSON.stringify(response.data));
      }
    },
    // eslint-disable-next-line
    async fetchPDF({ commit }, filename) {
      apiCall.get(hostForAPICalls + `/api/v1/pdf/${filename}`,
        {
          responseType: 'blob',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
        }
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        commit('setConsentPDF', url);
        state.bFetchingData=false;
      })
      .catch(error => {
        console.log("Error fetching consent pdf:",error);
        state.bFetchingData=false;
      });
    },
    // eslint-disable-next-line
    async downloadAttachment({ commit }, attachment) {
      apiCall.get(hostForAPICalls + `/api/v1/download/${attachment.id}`,
        {
          responseType: 'blob',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
        }
      ).then(response => {
        
        const blob = new Blob([response.data], { type: response.headers.get('Content-Type') });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = attachment.name;
        link.click();

        // Cleanup the temporary URL
        window.URL.revokeObjectURL(url);

        state.bFetchingData=false;
      })
      .catch(error => {
        console.log("Error fetching consent pdf:",error);
        state.bFetchingData=false;
      });
    },
    // eslint-disable-next-line
    async downloadPDF({ commit }, filename) {
      apiCall.get(hostForAPICalls + `/api/v1/download/pdf/${filename}`,
        {
          responseType: 'blob',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
        }
      ).then(response => {
        
        const blob = new Blob([response.data], { type: response.headers.get('Content-Type') });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();

        // Cleanup the temporary URL
        window.URL.revokeObjectURL(url);

        state.bFetchingData=false;
      })
      .catch(error => {
        console.log("Error fetching consent pdf:",error);
        state.bFetchingData=false;
      });
    },
  }
  
  const mutations = {
    setConsentRequest(state,consentRequest) { state.consentRequest=consentRequest},
    setConsentTypes(state,rTypes) {state.consentTypes=rTypes},
    setConsentPDF(state,consentPDF) { state.consentPDF=consentPDF}
  };
  
  export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
  };