import axios from 'axios'
import { createApp } from 'vue'
import { store } from './store'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import './assets/Custom-animate.css'
import './assets/animate.css'
import router from './router'
import i18n from './i18n'
import vue3GoogleLogin from 'vue3-google-login'
import Vue3Signature from "vue3-signature"
import Particles from "vue3-particles";

const app = createApp(App).use(router).use(Particles).use(Quasar, quasarUserOptions);
app.use(store, axios, i18n)
app.use(Vue3Signature)
app.use('vue-svg-loader')
app.use(vue3GoogleLogin, {
    clientId: ''
  })
app.mount('#app')