import { createStore } from 'vuex';
//import axios from 'axios';

import alerts from './store-alerts';
import users from './store-users';
import consentic from './store-consentic';
import public_consent_requests from './public/store-consent-requests';
//import { axios } from 'axios';
//import { VueAxios } from 'vue-axios'

//Vue.use(Vuex);
//Vue.use(axios);

//import createPersistedState from 'vuex-persistedstate';
//import { insights } from './insights.module';
//import { builder } from './builder.module';

window.version='CURRVER--CURRVER'; //Leave for replacement post compile
window.hash='CURRHASH--CURRHASH';

export const store = createStore({
        /*plugins: [createPersistedState({
            key: 'tandmapp_store'})],*/
        modules: {
            alerts,
            users,
            consentic,
            public_consent_requests
            //,
            //insights,
            //builder
        },

        //enable strict mode (adds overhead!)
        // for dev mode only
        //strict: process.env.DEV
    })
