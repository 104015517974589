import moment from 'moment-timezone'; // eslint-disable-line no-unused-vars
import format from 'quasar/src/utils/format.js';;
import axios from 'axios' // eslint-disable-line no-unused-vars

function cust_round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    var m2=Math.round(value * multiplier) / multiplier;
    return m2.toFixed(1);
}

const { pad } = format // eslint-disable-line no-unused-vars

var hostForAPICalls = location.hostname;
hostForAPICalls = (location.hostname === 'reg.consentic.com' ? "https://app.consentic.com" : "https://cert.consentic.com");
const apiCall = require('axios').create({ withCredentials: true, 
                                          headers: { 'Cache-Control': 'no-cache' },
                                          baseURL: hostForAPICalls});

const getDefaultState = () => {
  return {
    /**
     * Globally Shared Variables
     */
    dtmLastDataPoll:0,
    bPollingData:false,
    /** 
     * Current Version variables
     */
    currHash:'CURRHASH--CURRHASH',
    currVer:'CURRVER--CURRVER',
    /**
     * Consentic variables
     */
    rConsents:[],
    rPatients:[],
    rProcedures:[],
    rUsers:[],
    rTeamInfo:[],
    iCurrTeam:-1,
    /**
     * Alarms variables
     */
    currAlarmView: '',
    bAlarmsMuted:false,
    rAlarmsTree:[],
    rAlarms:[],
    CurrAlarmPath:"",
    CurrAlarmPathID:1,
    rAlarmExpPaths:[''],
    rAlarmExpIDs:[1],
    strAlarmSearch:"",
    bAlarmActive:false, //Filter Data Table to ONLY alarms if true
    bAlarmUnacknowledged:false, //Filter Data Table to ONLY alarms WITH no username if true
    bAlarmUnresolved:false, //Filter Data Table to ONLY alarms that have ended that are unresolved
    /**
     * Graphics Variable 
     */
    rGraphicsDataReq:[],
    rLatestGraphicsData:[],
    rLatestSummaryGraphicsData:[],
    tLastGraphics:0,
    currFridgeView: '',
    CurrGraphPath: '',
    CurrGraphPathID: 1,
    rGraphExpPaths: [''],
    rGraphExpIDs: [1],
    sCurrGraphicConf:{},
    rGraphicObjs:[],
    sGlobalGraphicsStyles:{},
    currGraphicsView:'View',
    /** 
     * Histories variables
     */
    CurrHistPath:'',
    CurrHistPathID: 1,
    rHistTree:[],
    rHistItems:[],
    rHistExpIDs:[1],
    rHistExpPaths:[''],
    currHistView:'',
    /** 
     * Network variables
     */
    CurrNetPath:'',
    CurrNetPathID: 1,
    rNetTree:[],
    rNetItems:[],
    rNetExpIDs:[1],
    rNetExpPaths:[''],
    rKits:[],
    currNetView:'',
    rControllerTypes:[],
    /** 
     * Module Location Trees
     */
    rControllerTree:[],
    rGraphicsTree:[],
    rHistoryTree:[],
    rMaintLogTree:[],
    rTennantTree:[],
    bApplsMuted: false,
    /**
     * Fridge Data Variables 
     * 
     * */
    DispSize:"5p",
    bSuccess:false,
    bFetchingData:false,
    rFridgesTree:[],
    rFridges:[],
    intFRefresh:-1,
    rDashInfo:[],
    custom_fields:[],
    ap_settings:[],
    rModuleList:[],
    CurrModule:"",
    CurrFridgePath:"",
    CurrFridgePathID:1,
    rFridgeExpPaths:[''],
    rFridgeExpIDs:[1],
    strFridgeSearch:"",
    strUserSearch: "",
    bFridgeAlarm:false, //Filter Data Table to ONLY alarms if true
    bFridgeUnacknowledged:false, //Filter Data Table to ONLY alarms WITH no username if true
    bFridgeUnresolved:false, //Filter Data Table to ONLY alarms that have ended that are unresolved
    currMeri:'AM',
    currHours:'11',
    currMinutes:'11',
    currSeconds:'11',
    currDay:14,
    currWDay:'Fri',
    currMonth:3,
    currYear:'2022',
    currTZ:'Australia/Brisbane',
    //Populated by query_location_tree.php
    dtmControllerPoll:'',
    rControllers:[],
    rLocations:[],
    rScreens:[],
    rSlotTypes:['f','b','Buf','i','void'], //Slots that become avaiable in the Slot Chooser
    //User logout catch variable:
    bLoggedOut: false,

    /**
     * ***END of Fridge Data variables***
     * 
     * */
    //Local store copy of user permissions
    permissions: {},
 }
}
const state = getDefaultState();

/* Commenting these out, this is not how you invoke from within the Store. You use the rootGetter on the Action
const computed = {
  ...mapGetters('users',['getCurrentStudy','getCurrentStudyTitle','getCurrentStudyID','getCurrentObserved','getUserProfile']),
};*/

const getters = {
    getPatientList:(state)         => { return state.rPatients },
    getUsersList:(state)         => { return state.rUsers },
    getProcedureList:(state)         => { return state.rProcedures },
    getConsents:(state)            => { return state.rConsents },
    getTeamInfo:(state)            => { return state.rTeamInfo[state.iCurrTeam] },
    getTeamList:(state)           => { return state.rTeamInfo },
    getCurrTeamId:(state)          => { return (state.iCurrTeam !=-1 && typeof state?.rTeamInfo[state?.iCurrTeam]?.id !=undefined ? state?.rTeamInfo[state?.iCurrTeam].id : -1 ) },
    getCurrTeamIndex:(state)        => { return state.iCurrTeam },
    getKits:(state)            => { return state.rKits },
    getContTypes:(state)       => { return state.rControllerTypes },
    getGraphicsCSS:(state)      => { return state.sGlobalGraphicsStyles},
    getLastDataPoll:(state)   => { return state.dtmLastDataPoll },
    GetGraphicsDataReq:(state)   => { return state.rGraphicsDataReq },
    GetGraphicsDataLatest:(state)   => { return state.rLatestGraphicsData },
    GetGraphicsSummaryDataLatest:(state)   => { return state.rLatestSummaryGraphicsData },
    GetGraphicsDataTime:(state)     => { return state.tLastGraphics },
    getGraphicsView:(state)     => { return state.currGraphicsView },
    GetGraphicObjs: (state)     => { return state.rGraphicObjs; },
    GetCurrGraphic: (state)     => { return state.sCurrGraphicConf },
    GetBFetchingData: (state)   => { return state.bFetchingData },
    GetUserSearch: (state)     => { return state.strUserSearch },
    GetFridgeSearch: (state)     => { return state.strFridgeSearch },
    GetAlarmSearch: (state)     => { return state.strAlarmSearch },
    GetLoggedOut: (state)       => { return state.bLoggedOut },
    GetSlotTypes: (state)       => { return state.rSlotTypes },
    GetCurrHistPath: (state)    => { return state.CurrHistPath },
    GetCurrHistPathID: (state)  => { return state.CurrHistPathID },
    GetHistExpPaths: (state)    => { return state.rHistExpPaths },
    GetHistExpIDs: (state)      => { return state.rHistExpIDs },
    GetHistItems: (state)       => { return state.rHistItems },
    GetCurrNetPath: (state)    => { return state.CurrNetPath },
    GetCurrNetPathID: (state)  => { return state.CurrNetPathID },
    GetNetExpPaths: (state)    => { return state.rNetExpPaths },
    GetNetExpIDs: (state)      => { return state.rNetExpIDs },
    GetNetItems: (state)       => { return state.rNetItems },
    GetActiveAlarmFilter: (state) => { return state.bAlarmActive},
    GetAlarmAckFilter: (state)  => { return state.bAlarmUnacknowledged},
    GetAlarmUnresFilter: (state) => { return state.bAlarmUnresolved},
    getAlarmForceRefresh: (state) => { return state.intFRefresh},
    getPertInfo: (state)        => { return state.rDashInfo},
    getHash: (state)            => { return state.currHash},
    getVer: (state)             => { return state.currVer},
    getUserPerms: (state)       => { return state.permissions},
    getFridgeView: (state)      => { return state.currFridgeView},
    getAlarmView: (state)       => { return state.currAlarmView},
    getHistView: (state)        => { return state.currHistView},
    getApplsMute: (state)       => { return state.bApplsMuted },
    getFridgeForceRefresh: (state) => { return state.intFRefresh },
    getAlarmsTree: (state)      => { return state.rAlarmsTree; },
    getAlarmsMuted: (state)     => { return state.bAlarmsMuted},
    getControllerTree: (state)  => { return state.rControllerTree; },
    getFridgesTree: (state)     => { return state.rFridgesTree; },
    getGraphicsTree: (state)    => { return state.rGraphicsTree; },
    GetCurrGraphPath: (state)   => { return state.CurrFridgePath },
    GetCurrGraphPathID: (state) => { return state.CurrGraphPathID },
    GetGraphExpPaths: (state)   => { return state.rGraphExpPaths },
    GetGraphExpIDs: (state)     => { return state.rGraphExpIDs },
    getHistoryTree: (state)     => { return state.rHistoryTree; },
    getMaintLogTree: (state)    => { return state.rMaintLogTree; },
    getTennantTree: (state)     => { return state.rTennantTree; },
    GetSuccess: (state)         => { return state.bSuccess},
    GetControllers: (state)     => { return state.rControllers},
    GetScreens: (state)         => { return state.rScreens},
    GetLocations: (state)       => { return state.rLocations},
    getAPSettings: (state)      => { return state.ap_settings},
    getCustFridgeFields: (state) => { return state.custom_fields},
    getModule: (state)          => { return state.CurrModule },
    GetModulesList: (state)     => { return state.rModuleList},
    GetAllowedModulesList: (state, getters, rootState, rootGetters) => {
        var x,y,rRet=[],perm=-1,rPerms=rootGetters['users/getUserPerms'];
        for(x=0;x<state.rModuleList.length;x++) {
            perm=-1;
            for(y=0;y<rPerms.length;y++) {
                //console.log("Looking for "+y,rPerms[y].module_id,state.rModuleList[x].id)
                if(rPerms[y].module_id==state.rModuleList[x].id) {
                    perm=rPerms[y].permissions
                    break;
                }
            }
            //console.warn(x,perm,state.rModuleList[x]);
            if(perm>0)
                rRet.push(state.rModuleList[x]);
        }
        return rRet;
    },
    GetTime: (state) => {
        var sTime={};
        sTime['Year']=state.currYear;
        sTime['Mth']=state.currMonth;
        sTime['Day']=state.currDay;
        sTime['WDay']=state.currWDay;
        sTime['Hour']=state.currHours;
        sTime['Min']=state.currMinutes;
        sTime['Sec']=state.currSeconds;
        sTime['Meri']=state.currMeri;
        return sTime;
    },
    GetTimeObj: (state) => {
        var dtmRet;
        if(state.currMeri=='PM')
            dtmRet=new Date(state.currYear,state.currMonth-1,state.currDay,state.currHours+12,state.currMinutes,state.currSeconds);
        else
            dtmRet=new Date(state.currYear,state.currMonth-1,state.currDay,state.currHours,state.currMinutes,state.currSeconds);
        return dtmRet;
    },
    GetAllBreachTypes:(state) => {return state.breach_types},
    GetAllFridges:(state) => {return state.rFridges},
    GetAllAlarms:(state) => {return state.rAlarms},
    GetAlarmFoldersAsOptions:(state)=> {
        var x=0,rRet=[];
        for(x=0;x<state.rAlarms.length;x++)
        {
            var sPath={};
            if(state.rAlarms[x].folder_path=='')
            {
                sPath['label']='/';
                sPath['value']='/';
            }
            else {
                sPath['label']=state.rAlarms[x].folder_path;
                sPath['value']=state.rAlarms[x].folder_path;
            }
            sPath['id']=state.rAlarms[x].id;
            if(sPath['id']!=null)
            rRet.push(sPath);
        }
        rRet.sort((a,b) => {
            var aCount=a.value.split('/').length-1, bCount=b.value.split('/').length-1;
            if(aCount < bCount) 
                return -1;
            else if (aCount > bCount) 
                return 1;
            else 
                return (a.value > b.value) ? 1 : ((a.value < b.value) ? -1 : 0);
        });
        //console.log("Options list is:",rRet);
        return rRet;
    },
    GetAllFoldersAsOptions:(state)=> {
        var x=0,rRet=[];
        for(x=0;x<state.rFridges.length;x++)
        {
            var sPath={};
            if(state.rFridges[x].path=='')
            {
                sPath['label']='/';
                sPath['value']='/';
            }
            else {
                sPath['label']=state.rFridges[x].path;
                sPath['value']=state.rFridges[x].path;
            }
            sPath['id']=state.rFridges[x].id;
            rRet.push(sPath);
        }
        rRet.sort((a,b) => {
            var aCount=a.value.split('/').length-1, bCount=b.value.split('/').length-1;
            if(aCount < bCount) 
                return -1;
            else if (aCount > bCount) 
                return 1;
            else 
                return (a.value > b.value) ? 1 : ((a.value < b.value) ? -1 : 0);
        });
        //console.log("Options list is:",rRet);
        return rRet;
    },
    GetHistFoldersAsOptions:(state)=> {
        var x=0,rRet=[];
        for(x=0;x<state.rHistoryTree.length;x++)
        {
            var sPath={};
            //console.warn("GetHistFoldersAsOptions Item:",state.rHistTree[x]);
            if(state.rHistTree[x].location_path=='')
            {
                sPath['label']='/';
                sPath['value']='/';
            }
            else {
                sPath['label']=state.rHistTree[x].location_name;
                sPath['value']=state.rHistTree[x].location_name;
            }
            sPath['id']=state.rHistTree[x].location_id;
            //console.warn("GetHistFoldersAsOptions sPath:",sPath);
            rRet.push(sPath);
        }
        rRet.sort((a,b) => {
            var aCount=a.value.split('/').length-1, bCount=b.value.split('/').length-1;
            if(aCount < bCount) 
                return -1;
            else if (aCount > bCount) 
                return 1;
            else 
                return (a.value > b.value) ? 1 : ((a.value < b.value) ? -1 : 0);
        });
        console.log("Options list is:",rRet);
        return rRet;
    },
    GetCurrFridgePath:    (state) => {return state.CurrFridgePath},
    GetCurrFridgePathID:  (state) => {return state.CurrFridgePathID},
    GetCurrAlarmPath:    (state) => {return state.CurrAlarmPath},
    GetCurrAlarmPathID:  (state) => {return state.CurrAlarmPathID},
    GetFridgeExpPaths:    (state) => {return state.rFridgeExpPaths},
    GetFridgeExpIDs:      (state) => {return state.rFridgeExpIDs},
    GetAlarmExpPaths:    (state) => {return state.rAlarmExpPaths},
    GetAlarmExpIDs:      (state) => {return state.rAlarmExpIDs},
    GetFridgeAlarmFilter:      (state) => {return state.bFridgeAlarm},
    GetFridgeAckFilter:      (state) => {return state.bFridgeUnacknowledged},
    GetFridgeUnresFilter:      (state) => {return state.bFridgeUnresolved},
    GetFridgeFaults:     (state) => { 
        var x,y,rRet=[];
        for(x=0;x<state.rFridges.length;x++) {
            for(y=0;y<state.rFridges[x]?.arr_items.length;y++) {
                if(rRet.findIndex(f=> f.id==state.rFridges[x].arr_items[y].id)==-1)
                    if (state.rFridges[x]?.arr_items[y]?.common_state!=0)
                        rRet.push(state.rFridges[x].arr_items[y]);
            }
        }
        //rRet.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        rRet.sort((a, b) => {
            if(a.common_state < b.common_state && a.common_state > 0 ) return -1;
            else if(a.common_state > b.common_state && b.common_state > 0 ) return 1;
            else {
                return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
            }});
        //console.warn("GetFridgeFaults returned:",rRet);
        return rRet;
    },
    GetHistCommErrs: (state) => { 
        var x,y,rRet=[];
        for(x=0;x<state.rHistTree.length;x++) {
            for(y=0;y<state.rHistTree[x]?.arr_items.length;y++) {
                if(rRet.findIndex(f=> f.id==state.rHistTree[x].arr_items[y].id)==-1 &&
                    (state.rFridges[x]?.arr_items[y]?.temp_alarm?.in_alarm==1 && state.rFridges[x]?.arr_items[y]?.disabled==0)) {
                    rRet.push(state.rFridges[x].arr_items[y]);
                }
            }
        }
        //rRet.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        return rRet;
    },
    GetFridgeAlarms:     (state) => { 
        var x,y,rRet=[];
        for(x=0;x<state.rFridges.length;x++) {
            for(y=0;y<state.rFridges[x]?.arr_items.length;y++) {
                if(rRet.findIndex(f=> f.id==state.rFridges[x].arr_items[y].id)==-1 &&
                    (state.rFridges[x]?.arr_items[y]?.common_state==1 || state.rFridges[x]?.arr_items[y]?.common_state==2)) {
                    rRet.push(state.rFridges[x].arr_items[y]);
                }
            }
        }
        //rRet.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        return rRet;
    },
    GetNetsInPath:     (state, getters, rootState, rootGetters) => {
        //if(typeof path=='undefined')path=state.CurrFridgePath;
        var x;
        //console.warn("GetNetsInPath entered with:",state.CurrNetPathID);
        if(state.CurrNetPathID=='') {
            var rPerms=rootGetters['users/getUserPerms'],intKey=-1;
            for(x=0;x<rPerms.length;x++)
              if(rPerms[x].module_id=='history') {
                intKey=x;
                break
              }
            if(intKey!=-1) {
                var intNetInd=state.rNetTree.findIndex(cNet => cNet.id==rPerms[intKey].mod_home);
                state.CurrNetPathID=(intNetInd!=-1 ? state.rNetTree[intNetInd].path : '');
            }
        }
        var CurrPathIndex=state.rNetTree.findIndex(cNet => cNet.location_id==state.CurrNetPathID);
        //console.log("CurrNetPathIndex:",CurrPathIndex);
        if(CurrPathIndex==-1 || state.rNetTree.length==0){
            console.warn("Path not found!",state.CurrNetPathID,state.rNetTree);
            return [];
        }
        else {
            var rRet=[],cPathInd=-1;
            for(x=0;x<state.rNetTree.length;x++) {
                //console.warn("Considering parent:",state.rNetTree[x]?.location_name, state.rNetTree[x]?.parent_id,state.rNetTree[x]?.location_id,state.CurrNetPathID)
                if(typeof state.rNetTree[x]?.parent_id !='undefined' && state.rNetTree[x].parent_id==state.CurrNetPathID) {
                    rRet.push(state.rNetTree[x]);
                }
                if(typeof state.rNetTree[x]?.location_id !='undefined' && state.rNetTree[x].location_id==state.CurrNetPathID) 
                    cPathInd=x;
            }
            //console.warn("cPathInd:",cPathInd,state.rNetTree[cPathInd]);
            if(cPathInd!=-1 && typeof state.rNetTree[cPathInd]?.arr_items !='undefined') {
                //console.warn("Considering items:",state.rNetTree[cPathInd].arr_items);
                rRet=rRet.concat(state.rNetTree[cPathInd].arr_items);
            }
            return rRet;
        } 
    },
    GetHistoriesInPath:     (state, getters, rootState, rootGetters) => {
        //if(typeof path=='undefined')path=state.CurrFridgePath;
        var x;
        //console.warn("GetHistoriesInPath entered with:",state.CurrHistPathID);
        if(state.CurrHistPathID=='') {
            var rPerms=rootGetters['users/getUserPerms'],intKey=-1;
            for(x=0;x<rPerms.length;x++)
              if(rPerms[x].module_id=='history') {
                intKey=x;
                break
              }
            if(intKey!=-1) {
                var intHistInd=state.rHistTree.findIndex(cHist => cHist.id==rPerms[intKey].mod_home);
                state.CurrHistPathID=(intHistInd!=-1 ? state.rHistTree[intHistInd].path : '');
            }
        }
        var CurrPathIndex=state.rHistTree.findIndex(cHist => cHist.location_id==state.CurrHistPathID);
        //console.log("CurrHistPathIndex:",CurrPathIndex);
        if(CurrPathIndex==-1 || state.rHistTree.length==0){
            console.warn("Path not found!",state.CurrHistPathID,state.rHistTree);
            return [];
        }
        else {
            var rRet=[],cPathInd=-1;
            for(x=0;x<state.rHistTree.length;x++) {
                //console.warn("Considering parent:",state.rHistTree[x]?.location_name, state.rHistTree[x]?.parent_id,state.rHistTree[x]?.location_id,state.CurrHistPathID)
                if(typeof state.rHistTree[x]?.parent_id !='undefined' && state.rHistTree[x].parent_id==state.CurrHistPathID) {
                    rRet.push(state.rHistTree[x]);
                }
                if(typeof state.rHistTree[x]?.location_id !='undefined' && state.rHistTree[x].location_id==state.CurrHistPathID) 
                    cPathInd=x;
            }
            //console.warn("cPathInd:",cPathInd,state.rHistTree[cPathInd]);
            if(cPathInd!=-1 && typeof state.rHistTree[cPathInd]?.arr_items !='undefined') {
                //console.warn("Considering items:",state.rHistTree[cPathInd].arr_items);
                rRet=rRet.concat(state.rHistTree[cPathInd].arr_items);
            }
            return rRet;
        } 
    },
    GetFoldersInPath:     (state) => {
        var x,y,z,rRet=[],rKeys=[];
        for(x=0;x<state.rFridges.length;x++)
        {
            if(state.rFridges[x]?.parent == state.CurrFridgePathID)
            {
                var sPath={};
                rKeys=Object.keys(state.rFridges[x])
                for(y=0;y<rKeys.length;y++)
                {
                    if(rKeys[y]!='arr_items')
                        sPath[rKeys[y]]=state.rFridges[x][rKeys[y]];
                }
                sPath['checked']=false;
                sPath['expand']=false;
                sPath['editName']=false;
                sPath['type']='folder';
                rRet.push(sPath);
            }
            else if(state.rFridges[x]?.id == state.CurrFridgePathID) {
                if(typeof state.rFridges[x]?.arr_items != 'undefined' && state.rFridges[x].arr_items.length > 0) {
                    for(y=0;y<state.rFridges[x].arr_items.length;y++)
                    {
                        sPath={};
                        rKeys=Object.keys(state.rFridges[x].arr_items[y]);
                        for(z=0;z<rKeys.length;z++)
                        {
                            sPath[rKeys[z]]=state.rFridges[x].arr_items[y][rKeys[z]];
                        }
                        sPath['in_alarm']=state.rFridges[x].arr_items[y]?.temp_alarm?.in_alarm;
                        sPath['ash_username']=state.rFridges[x].arr_items[y]?.temp_alarm?.ash_username;
                        sPath['username']=state.rFridges[x].arr_items[y]?.temp_alarm?.username;
                        sPath['parent']=state.rFridges[x].id;
                        sPath['checked']=false;
                        sPath['sSelDisabled']=(sPath.disabled==1 ? {'value':sPath['disabled'],'label':'Disabled'} : {'value':sPath['disabled'],'label':'Enabled'});
                        sPath['expand']=false;
                        sPath['editName']=false;
                        // Fill in required placeholder values for Folder structure (Vue requirement):
                        if(sPath.name=='Test4') console.log("Started with:",JSON.stringify(sPath));
                        if(typeof sPath?.temp_alarm != 'object' || sPath.temp_alarm==null) sPath.temp_alarm={};
                        if(typeof sPath.temp_alarm?.path_to_object=='undefined') sPath.temp_alarm.path_to_object='';
                        if(typeof sPath.temp_alarm?.displayPath=='undefined') sPath.temp_alarm.displayPath='';
                        if(typeof sPath.temp_alarm?.alarmActive=='undefined') sPath.temp_alarm.alarmActive=0;
                        if(typeof sPath.temp_alarm?.username=='undefined') sPath.temp_alarm.username=null;
                        if(typeof sPath.temp_alarm?.email=='undefined') sPath.temp_alarm.email=null;
                        if(typeof sPath.temp_alarm?.ash_username=='undefined') sPath.temp_alarm.ash_username=null;

                        if(typeof sPath?.status_alarm != 'object' || sPath.status_alarm==null) sPath.status_alarm={};
                        if(typeof sPath.status_alarm?.path_to_object=='undefined') sPath.status_alarm.path_to_object='';
                        if(typeof sPath.status_alarm?.displayPath=='undefined') sPath.status_alarm.displayPath='';
                        if(typeof sPath.status_alarm?.alarmActive=='undefined') sPath.status_alarm.alarmActive=0;
                        if(typeof sPath.status_alarm?.username=='undefined') sPath.status_alarm.username=null;
                        if(typeof sPath.status_alarm?.email=='undefined') sPath.status_alarm.email=null;
                        if(typeof sPath.status_alarm?.priority=='undefined') sPath.status_alarm.priority=0;

                        if(typeof sPath?.temp_trend != 'object' || sPath.temp_trend==null) sPath.temp_trend={};
                        if(typeof sPath.temp_trend?.path_to_object=='undefined' ) sPath.temp_trend.path_to_object='';
                        if(typeof sPath.temp_trend?.displayPath=='undefined') sPath.temp_trend.displayPath='';
                        if(typeof sPath.temp_trend?.enabled=='undefined' ) sPath.temp_trend.enabled=0;

                        if(typeof sPath?.door_alarm != 'object' || sPath.door_alarm==null) sPath.door_alarm={};
                        if(typeof sPath.door_alarm?.path_to_object=='undefined') sPath.door_alarm.path_to_object='';
                        if(typeof sPath.door_alarm?.displayPath=='undefined') sPath.door_alarm.displayPath='';
                        if(typeof sPath.door_alarm?.enabled=='undefined') sPath.door_alarm.enabled=0;
                        if(typeof sPath.door_alarm?.username=='undefined') sPath.door_alarm.username=null;
                        if(typeof sPath.door_alarm?.email=='undefined') sPath.door_alarm.email=null;

                        if(typeof sPath.breach != 'object' || sPath.breach==null) sPath.breach={};
                        if(typeof sPath.breach.enabled == 'undefined') sPath.breach.enabled=0;
                        
                        //console.log("Added fridge:",sPath);
                        rRet.push(sPath);
                    }
                }

            }
        }
        var rTest=rRet.sort((a,b) => (a.type=='folder' && b.type!='folder') ? -1 : ((b.type=='folder' && a.type!='folder') ? 1 : 
                                    ( a.name > b.name ? 1 : (a.name<b.name ? -1 : 0 ))));
        return rTest;
    }, 
    GetFridgesInPath:     (state, getters, rootState, rootGetters) => {
        //if(typeof path=='undefined')path=state.CurrFridgePath;
        var x;
        if(state.CurrFridgePath=='') {
            var rPerms=rootGetters['users/getUserPerms'],intKey=-1;
            for(x=0;x<rPerms.length;x++)
              if(rPerms[x].module_id=='fridges') {
                intKey=x;
                break
              }
            if(intKey!=-1) {
                var intFridgeInd=state.rFridges.findIndex(cFridge => cFridge.id==rPerms[intKey].mod_home);
                state.CurrFridgePath=(intFridgeInd!=-1 ? state.rFridges[intFridgeInd].path : '');
            }
        }
        var CurrPathIndex=state.rFridges.findIndex(cFridge => cFridge.path==state.CurrFridgePath);
        //console.log("CurrPathIndex:",CurrPathIndex);
        if(CurrPathIndex==-1 || state.rFridges.length==0){
            console.log("Path not found!",state.CurrFridgePath,state.rFridges);
            return [];
        }
        else {
            var rTemp,rRet=[];
            for(x=0;x<state.rFridges.length;x++) {
                if(typeof state.rFridges[x]?.path !='undefined' && state.rFridges[x].path.indexOf(state.CurrFridgePath)!=-1) {
                    //console.log("Considering Fridges:",state.rFridges[x]);
                    if(state.strFridgeSearch=='')
                    {
                        if(state.bFridgeAlarm) {
                            rTemp=state.rFridges[x].arr_items.filter((cFridge => cFridge.common_state==1 || cFridge.common_state==2));
                            if(rTemp!=null)
                                rRet=rRet.concat(rTemp);
                        }
                        else if(state.bFridgeUnacknowledged) {
                            rTemp=state.rFridges[x].arr_items.filter((cFridge => cFridge.common_state==3));
                            if(rTemp!=null)
                                rRet=rRet.concat(rTemp);
                        }
                        else if(state.bFridgeUnresolved) {
                            rTemp=state.rFridges[x].arr_items.filter((cFridge => cFridge.common_state==4));
                            if(rTemp!=null)
                                rRet=rRet.concat(rTemp);
                        }
                        else
                            rRet=rRet.concat(state.rFridges[x].arr_items);
                    }
                    else {
                        if(state.bFridgeAlarm) {
                            rTemp=state.rFridges[x].arr_items.filter(
                                cFridge => (cFridge.name.toLowerCase().indexOf(state.strFridgeSearch.toLowerCase())!=-1 || cFridge.location_path.toLowerCase().indexOf(state.strFridgeSearch.toLowerCase())!=-1) 
                                && (cFridge.common_state==1 || cFridge.common_state==2));
                        }
                        else if(state.bFridgeUnacknowledged)
                            rTemp=state.rFridges[x].arr_items.filter(
                                cFridge => (cFridge.name.toLowerCase().indexOf(state.strFridgeSearch.toLowerCase())!=-1 || cFridge.location_path.toLowerCase().indexOf(state.strFridgeSearch.toLowerCase())!=-1) 
                                && cFridge.common_state==3);
                        else if(state.bFridgeUnresolved) {
                            rTemp=state.rFridges[x].arr_items.filter(
                                cFridge => (cFridge.name.toLowerCase().indexOf(state.strFridgeSearch.toLowerCase())!=-1 || cFridge.location_path.toLowerCase().indexOf(state.strFridgeSearch.toLowerCase())!=-1)
                                && cFridge.common_state==4);
                        }
                        else {
                            rTemp=state.rFridges[x].arr_items.filter(cFridge => cFridge.name.toLowerCase().indexOf(state.strFridgeSearch.toLowerCase())!=-1 || cFridge.location_path.toLowerCase().indexOf(state.strFridgeSearch.toLowerCase())!=-1);
                        }
                        if(rTemp!=null)
                            rRet=rRet.concat(JSON.parse(JSON.stringify(rTemp)));
                    }
                /*else{
                    console.log("Nope, ignored:",state.rFridges[CurrPathIndex].path);
                }*/
                }

            }
            return rRet;
        } 
    },
    GetAlarmsInPath:     (state, getters, rootState, rootGetters) => {
        //if(typeof path=='undefined')path=state.CurrAlarmPath;
        var x,iHome=1;
        if(state.CurrAlarmPath=='') {
            var rPerms=rootGetters['users/getUserPerms'],intKey=-1;
            for(x=0;x<rPerms.length;x++)
              if(rPerms[x].module_id=='alarms') {
                intKey=x;
                break
              }
            //console.warn("Key for modPerms:",intKey,rPerms[intKey].mod_home);
            if(intKey!=-1) {
                var intAlarmInd=state.rAlarms.findIndex(cAlarm => cAlarm.id==rPerms[intKey].mod_home);
                iHome=rPerms[intKey].mod_home;
                state.CurrAlarmPath=(intAlarmInd!=-1 ? state.rAlarms[intAlarmInd].path : 'home');
            }
        }
        if (typeof state.CurrAlarmPath=='undefined' || state.CurrAlarmPath==null) {
            state.CurrAlarmPath='/';
        }
        //console.log("Find Alarms In Path:",state.CurrAlarmPath,state.CurrAlarmPathID,iHome,state.rAlarms);
        var CurrPathIndex=state.rAlarms.findIndex(cAlarm => cAlarm.id==iHome);
        //console.log("Alarm CurrPathIndex:",CurrPathIndex,state.rAlarms.length);
        if(CurrPathIndex==-1 || state.rAlarms.length==0){
            console.log("Path not found!",state.CurrAlarmPath,state.rAlarms);
            return [];
        }
        else {
            var rTemp,rRet=[];
            for(x=0;x<state.rAlarms.length;x++) {
                if(typeof state.rAlarms[x]?.folder_path !='undefined' && (state.CurrAlarmPath=='home' || state.rAlarms[x].folder_path.indexOf(state.CurrAlarmPath)!=-1)) {
                        if(state.strAlarmSearch=='')
                    {
                        if(state.bAlarmActive) {
                            rTemp=state.rAlarms[x].arr_items.filter((cAlarm => cAlarm?.in_alarm==1 && cAlarm?.enabled==1));
                            if(rTemp!=null)
                                rRet=rRet.concat(rTemp);
                        }
                        else if(state.bAlarmUnacknowledged) {
                            rTemp=state.rAlarms[x].arr_items.filter(
                                (cAlarm => cAlarm?.resolved==0 && cAlarm?.enabled==1 && cAlarm?.in_alarm==0 && (cAlarm?.assigned_user=='' || cAlarm?.assigned_user==null)));
                            if(rTemp!=null)
                                rRet=rRet.concat(rTemp);
                        }
                        else if(state.bAlarmUnresolved) {
                            rTemp=state.rAlarms[x].arr_items.filter(
                                (cAlarm => cAlarm?.resolved==0 && cAlarm?.enabled==1 && cAlarm?.in_alarm==0 ));
                            if(rTemp!=null)
                                rRet=rRet.concat(rTemp);
                        }
                        else
                            rRet=rRet.concat(state.rAlarms[x].arr_items);
                    }
                    else {
                        if(state.bAlarmActive) {
                            rTemp=state.rAlarms[x].arr_items.filter(
                                cAlarm => (cAlarm.name.toLowerCase().indexOf(state.strAlarmSearch.toLowerCase())!=-1 || cAlarm.folder_path.toLowerCase().indexOf(state.strAlarmSearch.toLowerCase())!=-1) && cAlarm?.in_alarm==1);
                        }
                        else if(state.bAlarmUnacknowledged)
                            rTemp=state.rAlarms[x].arr_items.filter(
                                cAlarm => (cAlarm.name.toLowerCase().indexOf(state.strAlarmSearch.toLowerCase())!=-1 || cAlarm.folder_path.toLowerCase().indexOf(state.strAlarmSearch.toLowerCase())!=-1) 
                                && cAlarm?.in_alarm==1 
                                && (cAlarm?.username=='' || cAlarm?.username==null));
                        else if(state.bAlarmUnresolved) {
                            rTemp=state.rAlarms[x].arr_items.filter(
                                cAlarm => (cAlarm.name.toLowerCase().indexOf(state.strAlarmSearch.toLowerCase())!=-1 || cAlarm.folder_path.toLowerCase().indexOf(state.strAlarmSearch.toLowerCase())!=-1) &&
                                cAlarm?.resolved==0 && cAlarm?.enabled==1 && cAlarm?.in_alarm==0 );
                        }
                        else {
                            //console.warn("Considering String Search:",state.rAlarms[x].arr_items);
                            rTemp=state.rAlarms[x].arr_items.filter(cAlarm => 
                                cAlarm.name.toLowerCase().indexOf(state.strAlarmSearch.toLowerCase())!=-1 || 
                                cAlarm.folder_path.toLowerCase().indexOf(state.strAlarmSearch.toLowerCase())!=-1);
                        }
                        if(rTemp!=null)
                            rRet=rRet.concat(JSON.parse(JSON.stringify(rTemp)));
                    }
                /*else{
                    console.log("Nope, ignored:",state.rAlarms[CurrPathIndex].folder_path);
                }*/
                }

            }
            //console.warn("Alarms in Path returned:",rRet);
            return rRet;
        } 
    },
    GetAlarmFoldersInPath:     (state) => {
        var x,y,z,rRet=[],rKeys=[];
        for(x=0;x<state.rAlarms.length;x++)
        {
            if(state.rAlarms[x]?.parent == state.CurrAlarmPathID)
            {
                var sPath={};
                rKeys=Object.keys(state.rAlarms[x])
                for(y=0;y<rKeys.length;y++)
                {
                    if(rKeys[y]!='arr_items')
                        sPath[rKeys[y]]=state.rAlarms[x][rKeys[y]];
                }
                sPath['checked']=false;
                sPath['expand']=false;
                sPath['editName']=false;
                sPath['type']='folder';
                rRet.push(sPath);
            }
            else if(state.rAlarms[x]?.id == state.CurrAlarmPathID) {
                if(typeof state.rAlarms[x]?.arr_items != 'undefined' && state.rAlarms[x].arr_items.length > 0) {
                    for(y=0;y<state.rAlarms[x].arr_items.length;y++)
                    {
                        sPath={};
                        rKeys=Object.keys(state.rAlarms[x].arr_items[y]);
                        for(z=0;z<rKeys.length;z++)
                        {
                            sPath[rKeys[z]]=state.rAlarms[x].arr_items[y][rKeys[z]];
                        }
                        sPath['in_alarm']=state.rAlarms[x].arr_items[y]?.in_alarm;
                        sPath['username']=state.rAlarms[x].arr_items[y]?.assigned_user;
                        sPath['parent']=state.rAlarms[x].id;
                        sPath['folder_path']=state.rAlarms[x].folder_path;
                        sPath['checked']=false;
                        sPath['sSelDisabled']=(sPath.disabled==1 ? {'value':sPath['disabled'],'label':'Disabled'} : {'value':sPath['disabled'],'label':'Enabled'});
                        sPath['measurement']=(sPath.rel_alarm_cont_id==null ? 'absolute' : 'relative' );
                        sPath['expand']=false;
                        sPath['editName']=false;
                        //Fill in required placeholder values for Folder structure (Vue requirement):
                        //if(sPath.name=='Test4') console.log("Started with:",JSON.stringify(sPath));
                        
                        //console.log("Added fridge:",sPath);
                        rRet.push(sPath);
                    }
                }

            }
        }
        var rTest=rRet.sort((a,b) => (a.type=='folder' && b.type!='folder') ? -1 : ((b.type=='folder' && a.type!='folder') ? 1 : 
                                    ( a.name > b.name ? 1 : (a.name<b.name ? -1 : 0 ))));
        return rTest;
    }, 
    GetSearchUsers: (state) => {
        var rUsersList=state.rUsers;
        return rUsersList.find(cUser => cUser.username.toLowerCase.indexOf(state.strUserSearch)!=-1 || cUser.email.toLowerCase.indexOf(state.strUserSearch)!=-1);
    },
    GetFridgesDataTable:     (state,getters) => {
        var rFridgeList=getters.GetFridgesInPath;
        var x,rDTList=[],n,rFIDs=[],intDupInd,bDupVFridge;
        for(x=0;x<rFridgeList.length;x++){
            bDupVFridge=false;
            if(rFIDs.includes(rFridgeList[x].id)) {
                //If we've already added this FID and this one is the REAL one, swap them
                intDupInd=rDTList.findIndex(f => f?.id==rFridgeList[x].id);
                //console.warn(rFridgeList[x].name, rFridgeList[x].id,intDupInd);
                if(rFridgeList[x].type=='virtualfridge') //Dup VFridge, ignore
                    bDupVFridge=true;
                else if(intDupInd!=-1) { //New fridge is the real one, remove the virtual
                    //console.log("Removing:",rDTList[intDupInd].name,rDTList[intDupInd].location_path);
                    rDTList.splice(intDupInd,1);
                    //console.log(rFridgeList);
                }
            }
            if(!bDupVFridge) { //rFridgeList[x].disabled==0
                rFIDs.push(rFridgeList[x].id);
                var sDTFridge={};
                sDTFridge.id=rFridgeList[x].id;
                sDTFridge.name=rFridgeList[x].name;
                sDTFridge.disabled=rFridgeList[x].disabled;
                sDTFridge.in_alarm_sort=(rFridgeList[x]?.disabled==1 ||rFridgeList[x]?.disabled==2 ? -1 : 
                    (rFridgeList[x]?.disabled==0  && typeof rFridgeList[x]?.temp_alarm?.last_value=='undefined' ? -2 : 
                    (rFridgeList[x]?.temp_alarm?.in_alarm==1 && rFridgeList[x]?.temp_alarm?.resolved==0 ? 4 :
                        (rFridgeList[x]?.temp_alarm?.in_alarm==1 && rFridgeList[x]?.temp_alarm?.resolved==1 ? 3 : 
                            (!rFridgeList[x]?.temp_alarm?.in_alarm==1 && rFridgeList[x]?.temp_alarm?.resolved==0 && (typeof rFridgeList[x]?.temp_alarm?.username=='undefined' || rFridgeList[x]?.temp_alarm?.username==null 
                                || rFridgeList[x]?.temp_alarm?.username=='' ) ? 2 :
                            (rFridgeList[x]?.temp_alarm?.in_alarm==0 && rFridgeList[x]?.temp_alarm?.resolved==0 && rFridgeList[x]?.temp_alarm?.username?.length > 0 ? 1 :
                                (!rFridgeList[x]?.temp_alarm?.in_alarm==1 && (typeof rFridgeList[x]?.temp_alarm?.username=='undefined' || rFridgeList[x]?.temp_alarm?.username==null 
                                    || rFridgeList[x]?.temp_alarm?.username==''==1 ) && rFridgeList[x]?.temp_alarm?.resolved==1 ? 0 : -1)))))));
                /*sDTFridge.in_alarm_sort=
                    (rFridgeList[x]?.temp_alarm?.username=='' || rFridgeList[x]?.temp_alarm?.username==null 
                     || typeof rFridgeList[x]?.temp_alarm?.username=='undefined'? 1 : 0 ) : 0 );*/
                sDTFridge.selected=false; //Placeholder data for Checkbox control
                sDTFridge.expand=false; //Placeholder data for Expansion column
                sDTFridge.view='FaultReport'; //Placeholder data for View controls
                sDTFridge.bChecked=false;

                sDTFridge.controller_online=rFridgeList[x]?.controller_online;

                sDTFridge.breach_alarm_state=rFridgeList[x]?.breach_alarm_state;
                sDTFridge.breach_alarm_type=rFridgeList[x]?.breach_alarm_type;
                sDTFridge.breach_enabled=rFridgeList[x]?.breach_enabled;

                sDTFridge.common_state=rFridgeList[x]?.common_state;
                sDTFridge.temp_alarm_state=rFridgeList[x]?.temp_alarm_state;
                sDTFridge.status_alarm_state=rFridgeList[x]?.status_alarm_state;
                sDTFridge.door_alarm_state=rFridgeList[x]?.door_alarm_state;
                
                sDTFridge.sensor_min_value=rFridgeList[x]?.sensor_min_value;
                sDTFridge.sensor_max_value=rFridgeList[x]?.sensor_max_value;
                sDTFridge.sensor_is_out_of_range=rFridgeList[x]?.sensor_is_out_of_range;

                sDTFridge.temp_alarm=rFridgeList[x]?.temp_alarm;
                if(typeof sDTFridge?.temp_alarm == 'undefined' || sDTFridge.temp_alarm == null )
                    sDTFridge.temp_alarm={};
                sDTFridge.last_value=cust_round(rFridgeList[x]?.temp_alarm?.last_value,1);
                sDTFridge.in_alarm=rFridgeList[x]?.temp_alarm?.in_alarm;
                sDTFridge.temp_trend=rFridgeList[x]?.temp_trend;
                sDTFridge.temp_alarm_id=rFridgeList[x]?.temp_alarm_id;
                sDTFridge.status_alarm_id=rFridgeList[x]?.status_alarm_id;
                sDTFridge.status_alarm=rFridgeList[x]?.status_alarm;
                sDTFridge.status_alarm.alarm_value_active=0;
                if (sDTFridge.status_alarm?.enabled==1) {
                    if (sDTFridge.status_alarm?.last_value=='false' || sDTFridge.status_alarm?.last_value==0) {
                        if (sDTFridge.status_alarm?.send_on==0) {
                            sDTFridge.status_alarm.alarm_value_active=0;
                        }
                        if (sDTFridge.status_alarm?.send_on==1) {
                            sDTFridge.status_alarm.alarm_value_active=1;
                        }
                    }
                    if (sDTFridge.status_alarm?.last_value=='true' || sDTFridge.status_alarm?.last_value==1) {
                        if (sDTFridge.status_alarm?.send_on==0) {
                            sDTFridge.status_alarm.alarm_value_active=1;
                        }
                        if (sDTFridge.status_alarm?.send_on==1) {
                            sDTFridge.status_alarm.alarm_value_active=0;
                        }
                    }
                }


                sDTFridge.door_alarm_id=rFridgeList[x]?.door_alarm_id;
                sDTFridge.door_alarm=rFridgeList[x]?.door_alarm;
                sDTFridge.door_alarm.door_open=0;
                if (sDTFridge.door_alarm?.enabled==1) {
                    if (sDTFridge.door_alarm?.last_value=='false' || sDTFridge.door_alarm?.last_value==0) {
                        if (sDTFridge.door_alarm?.send_on==0) {
                            sDTFridge.door_alarm.door_open=0;
                        }
                        if (sDTFridge.door_alarm?.send_on==1) {
                            sDTFridge.door_alarm.door_open=1;
                        }
                    }
                    if (sDTFridge.door_alarm?.last_value=='true' || sDTFridge.door_alarm?.last_value==1) {
                        if (sDTFridge.door_alarm?.send_on==0) {
                            sDTFridge.door_alarm.door_open=1;
                        }
                        if (sDTFridge.door_alarm?.send_on==1) {
                            sDTFridge.door_alarm.door_open=0;
                        }
                    }
                }

                sDTFridge.location_path=rFridgeList[x].location_path;
                sDTFridge.low_24=cust_round(rFridgeList[x].low_24,1);
                sDTFridge.high_24=cust_round(rFridgeList[x].high_24,1);
                n=rFridgeList[x]?.lf_24;
                sDTFridge.lf_24=( n!=null ? n.toLocaleString('en-us',{'minimumFractionDigits':2,'maximumFractionDigits':2}) : "0.00");
                sDTFridge.time_oor_24=rFridgeList[x].time_oor_24;
                sDTFridge.oor_count_24=rFridgeList[x].oor_count_24;
                sDTFridge.low_30=cust_round(rFridgeList[x].low_30,1);
                sDTFridge.high_30=cust_round(rFridgeList[x].high_30,1);
                n=rFridgeList[x]?.lf_30;
                sDTFridge.lf_30=( n!=null ? n.toLocaleString('en-us',{'minimumFractionDigits':2,'maximumFractionDigits':2}) : "0.00");
                sDTFridge.time_oor_30=rFridgeList[x].time_oor_30;
                sDTFridge.oor_count_30=rFridgeList[x].oor_count_30;
                sDTFridge.custom_fields=rFridgeList[x]?.custom_fields.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
                sDTFridge.unit_of_measure=rFridgeList[x]?.temp_trend?.unit_of_measure;
                if(typeof sDTFridge?.temp_alarm?.path_to_object=='undefined') 
                    sDTFridge.temp_alarm.path_to_object='';
                
                rDTList.push(sDTFridge);
            }
        }
        //Sort by Alarm state first, and Lee Factor Second
        rDTList.sort((a, b) => {
            if(a.in_alarm_sort > b.in_alarm_sort ) return -1;
            else if(a.in_alarm_sort < b.in_alarm_sort ) return 1;
            else {
                if(parseFloat(a.lf_24.replaceAll(',', '')) > parseFloat(b.lf_24.replaceAll(',', ''))) return -1;
                else if(parseFloat(a.lf_24.replaceAll(',', '')) < parseFloat(b.lf_24.replaceAll(',', ''))) return 1;
                else return 0;
            }
                
            /*if(a.in_alarm > b.in_alarm) return -1;
            else if(a.in_alarm < b.in_alarm) return 1;
            else {
            if (parseFloat(a.lf_24) > parseFloat(b.lf_24)) return -1;
            else if (parseFloat(a.lf_24) < parseFloat(b.lf_24)) return 1;
            else return 0;
            }*/
            //if(a.in_alarm==b.in_alarm) return parseFloat(a.lf_24)>parseFloat(b.lf_24);
            //else return a.in_alarm>b.in_alarm;
        });

        return rDTList;
    },
    GetHistTree: (state) => { return state.rHistTree; },
    GetNetTree: (state) => { return state.rNetTree; },
    GetHistDataTable: (state,getters) => {
        var x, rRet=[];
        var rHistList=getters.GetHistoriesInPath;
        //console.warn("HistoriesInPath:",rHistList);
        for(x=0;x<rHistList.length;x++) {
            if(rHistList[x].location_id!=state.CurrHistPathID) {
                rHistList[x].expanded=false;
                rHistList[x].selected=false;
                try {
                    rHistList[x].parsedHist=(typeof rHistList[x].histories!='undefined' ? JSON.parse(rHistList[x].histories) : []);
                }
                catch(e) {
                    rHistList[x].parsedHist=[];
                }
                rHistList[x].id=(typeof rHistList[x].location_id!='undefined' ? rHistList[x].location_id : 
                                    (typeof rHistList[x].graph_id!='undefined' ? rHistList[x].graph_id :
                                    (typeof rHistList[x].point_id!='undefined' ? rHistList[x].point_id : rHistList[x].id )));
                rHistList[x].icon='';
                if(typeof rHistList[x].arr_items!='undefined') rHistList[x].sort=3;//Folder
                else if(typeof rHistList[x].graph_id!='undefined') rHistList[x].sort=2;//Graphs
                else if(typeof rHistList[x].point_id!='undefined') rHistList[x].sort=1;//Points
                else rHistList[x].sort=0;//Something else?
                rRet.push(rHistList[x]);
            }
        }
        rRet.sort((a,b)=>{
            return (a.sort==b.sort ? (a.name==b.name ? 0 : (a.name > b.name ? 1 : -1)) : (a.sort < b.sort ? 1 : -1))
        });
        //console.warn("GetHistDataTable returned:",rRet);
        return rRet;
    },
    GetKitsTable: (state,getters) => {
        var x, rRet=[];
        var rKitsList=getters.getKits;
        //console.warn("Kits:",rKitsList,);
        for(x=0;x<rKitsList.length;x++) {
            rKitsList[x].expanded=false;
            rKitsList[x].selected=false;
            rKitsList[x].sort=1;
            rRet.push(rKitsList[x]);
        }

        rRet.sort((a,b)=>{
            return (a.sort==b.sort ? (a.name==b.name ? 0 : (a.name > b.name ? 1 : -1)) : (a.sort < b.sort ? 1 : -1))
        });
        console.warn("GetNetDataTable returned:",rRet);
        return rRet;
    },
    GetNetDataTable: (state,getters) => {
        var x, rRet=[];
        var rNetList=getters.GetControllers;
        //console.warn("Controllers First:",rNetList,state.CurrNetPathID);
        for(x=0;x<rNetList.length;x++) {
            if(rNetList[x].location_id==state.CurrNetPathID) {
                rNetList[x].expanded=false;
                rNetList[x].selected=false;
                rNetList[x].id=(typeof rNetList[x].location_id!='undefined' ? rNetList[x].location_id : 
                                    (typeof rNetList[x].graph_id!='undefined' ? rNetList[x].graph_id :
                                    (typeof rNetList[x].point_id!='undefined' ? rNetList[x].point_id : rNetList[x].id )));
                rNetList[x].icon='';
                rNetList[x].name=rNetList[x].controller_name;
                rNetList[x].type='controller';
                if(typeof rNetList[x].arr_items!='undefined') rNetList[x].sort=3;//Folder
                else if(typeof rNetList[x].graph_id!='undefined') rNetList[x].sort=2;//Graphs
                else if(typeof rNetList[x].point_id!='undefined') rNetList[x].sort=1;//Points
                else rNetList[x].sort=2;
                rRet.push(rNetList[x]);
            }
        }
        //console.warn("Screens Second:",getters.GetScreens);
        for(x=0;x<getters.GetScreens.length;x++) {
            if(getters.GetScreens[x].location_id==state.CurrHistPathID) {
                getters.GetScreens[x].expanded=false;
                getters.GetScreens[x].selected=false;
                try {
                    getters.GetScreens[x].parsedHist=(typeof getters.GetScreens[x].histories!='undefined' ? JSON.parse(getters.GetScreens[x].histories) : []);
                }
                catch(e) {
                    getters.GetScreens[x].parsedHist=[];
                }
                getters.GetScreens[x].id=(typeof getters.GetScreens[x].location_id!='undefined' ? getters.GetScreens[x].location_id : 
                                    (typeof getters.GetScreens[x].graph_id!='undefined' ? getters.GetScreens[x].graph_id :
                                    (typeof getters.GetScreens[x].point_id!='undefined' ? getters.GetScreens[x].point_id : getters.GetScreens[x].id )));
                getters.GetScreens[x].icon='';
                getters.GetScreens[x].type='screen';
                if(typeof getters.GetScreens[x].arr_items!='undefined') getters.GetScreens[x].sort=3;//Folder
                else if(typeof getters.GetScreens[x].graph_id!='undefined') getters.GetScreens[x].sort=2;//Graphs
                else if(typeof getters.GetScreens[x].point_id!='undefined') getters.GetScreens[x].sort=1;//Points
                else getters.GetScreens[x].sort=1;//Something else?
                rRet.push(getters.GetScreens[x]);
            }
        }
        rRet.sort((a,b)=>{
            return (a.sort==b.sort ? (a.name==b.name ? 0 : (a.name > b.name ? 1 : -1)) : (a.sort < b.sort ? 1 : -1))
        });
        console.warn("GetNetDataTable returned:",rRet);
        return rRet;
    },
    GetAlarmsDataTable:     (state,getters) => {
        var rAlarmList=getters.GetAlarmsInPath;
        //console.log("Alarms in Path:",rAlarmList);
        var x,rDTList=[];//,n,rFIDs=[],intDupInd,bDupVAlarm;
        for(x=0;x<rAlarmList.length;x++){
            /*bDupVAlarm=false;
            if(rFIDs.includes(rAlarmList[x].id)) {
                //If we've already added this FID and this one is the REAL one, swap them
                intDupInd=rDTList.findIndex(f => f?.id==rAlarmList[x].id);
                //console.warn(rAlarmList[x].name, rAlarmList[x].id,intDupInd);
                if(rAlarmList[x].type=='virtualfridge') //Dup VAlarm, ignore
                    bDupVAlarm=true;
                else if(intDupInd!=-1) { //New fridge is the real one, remove the virtual
                    //console.log("Removing:",rDTList[intDupInd].name,rDTList[intDupInd].location_path);
                    rDTList.splice(intDupInd,1);
                    //console.log(rAlarmList);
                }
            }*/
            if(rAlarmList[x].enabled==1 ) {
                //rFIDs.push(rAlarmList[x].id);
                var sDTAlarm=JSON.parse(JSON.stringify(rAlarmList[x]));
                sDTAlarm['selected']=false;
                sDTAlarm['expansion']=false;
                sDTAlarm['measurement']=(sDTAlarm.rel_alarm_cont_id==null ? 'absolute' : 'relative' );
                sDTAlarm['in_alarm']=(sDTAlarm['in_alarm']=='0' ? false : true);
                sDTAlarm['resolved']=(sDTAlarm['resolved']=='0' ? false : true);
                sDTAlarm['enabled']=(sDTAlarm['enabled']=='0' ? false : true);
                sDTAlarm.in_alarm_sort=(sDTAlarm?.disabled==1 ||sDTAlarm?.disabled==2 ? -1 : 
                    (sDTAlarm?.disabled==0  && typeof sDTAlarm?.temp_alarm?.last_value=='undefined' ? -2 : 
                    (sDTAlarm?.temp_alarm?.in_alarm==1 && sDTAlarm?.temp_alarm?.resolved==0 ? 4 :
                        (sDTAlarm?.temp_alarm?.in_alarm==1 && sDTAlarm?.temp_alarm?.resolved==1 ? 3 : 
                            (!sDTAlarm?.temp_alarm?.in_alarm==1 && sDTAlarm?.temp_alarm?.resolved==0 && (typeof sDTAlarm?.temp_alarm?.username=='undefined' || sDTAlarm?.temp_alarm?.username==null 
                                || sDTAlarm?.temp_alarm?.username=='' ) ? 2 :
                            (sDTAlarm?.temp_alarm?.in_alarm==0 && sDTAlarm?.temp_alarm?.resolved==0 && sDTAlarm?.temp_alarm?.username?.length > 0 ? 1 :
                                (!sDTAlarm?.temp_alarm?.in_alarm==1 && (typeof sDTAlarm?.temp_alarm?.username=='undefined' || sDTAlarm?.temp_alarm?.username==null 
                                    || sDTAlarm?.temp_alarm?.username==''==1 ) && sDTAlarm?.temp_alarm?.resolved==1 ? 0 : -1)))))));
                /*sDTAlarm['in_alarm_sort']=(rAlarmList[x]?.in_alarm==1 ? 
                    (rAlarmList[x]?.assigned_user=='' || rAlarmList[x]?.assigned_user==null 
                     || typeof rAlarmList[x]?.assigned_user=='undefined'? 3 : 2 ) :
                     ( rAlarmList[x]?.resolved==0 ? 1 :  0 ));*/
                sDTAlarm['full_path']=rAlarmList[x].folder_path+'/'+rAlarmList[x].name;
                rDTList.push(sDTAlarm);
            }
        }
        //Sort by Alarm state first
        //console.warn("RDTList before:",JSON.stringify(rDTList));
        rDTList.sort((a, b) => {
            if(a.in_alarm_sort > b.in_alarm_sort ) return -1;
            else if(a.in_alarm_sort < b.in_alarm_sort ) return 1;
            else return 0;
        });
        //console.warn("RDTList after:",JSON.stringify(rDTList));

        return rDTList;
    },
    GetUsersDataTable: (state) => {
        var rUsersList=state.rUsers;
        var x,sMod={}, rDTUserList=[],sUser,strUser,strEmail;
        //var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year,month,date;
        //Pre-Build the placeholder rAccess struct for users
        var rTempAccess={};
        for(x=0;x<state.rModuleList.length;x++) {
            sMod={};
            sMod.permissions=0;
            sMod.permissionsText='';
            sMod.mod_home=1;
            sMod.mod_home_full_path='/home';
            sMod.mod_name=state.rModuleList[x].name;
            sMod.mobile_home=0;
            sMod.mod_id=state.rModuleList[x].id;
            rTempAccess[state.rModuleList[x].name]=sMod;
        }
        for(x=0;x<rUsersList.length;x++){
            strUser=rUsersList[x].username.toString();
            strEmail=rUsersList[x].email.toString();
            if(state.strUserSearch=='' || ( strUser.toLowerCase().indexOf(state.strUserSearch.toLowerCase())!=-1 ||
                                            strEmail.toLowerCase().indexOf(state.strUserSearch.toLowerCase())!=-1)) {
                sUser={};
                sUser.selected=false;
                sUser.rAccess=rTempAccess;
                sUser.username=rUsersList[x].username;
                sUser.email=rUsersList[x].email;
                sUser.fullname=rUsersList[x].fullname;
                sUser.controls=''; //Placeholder for controls column
                sUser.phone=rUsersList[x].phone;
                sUser.autoLockout=false;
                sUser.autoLockoutTime=5;
                sUser.DataSave=false;
                if (rUsersList[x].expiry_date==null || isNaN(rUsersList[x].expiry_date)) {
                    sUser.raw_expiry_date="";
                    sUser.expiry_date="";
                }
                else {
                    sUser.raw_expiry_date=new Date(rUsersList[x].expiry_date*1000);
                    year = sUser.raw_expiry_date.getFullYear();
                    year=year.toString().padStart(2,'0');
                    month = sUser.raw_expiry_date.getMonth()+1;
                    month=month.toString().padStart(2,'0');
                    date = sUser.raw_expiry_date.getDate();
                    sUser.expiry_date=year+"-"+month+"-"+date;
                }
                sUser.user_id=rUsersList[x].user_id;
                rDTUserList.push(sUser);
            }
        }
        return rDTUserList;

    },
    /*getClearTask: (state) => { return state.ClearTask},
    getClearSurvey: (state) => { return state.clearSurvey},
    getCurrentObservedPosition: (state) => { return state.currentObservedPosition},
    getUsersStudyLabels: (state, getters, rootState)=> {
        return rootState.users.currentStudy.studyLabelSet.studyLabels;},*/
}

const actions = {
    fetchConsents({state,commit}) {
        state.bFetchingData=true;
        if(localStorage.getItem('token')!=null) {
            apiCall.get(hostForAPICalls+'/api/consent-requests/'+state.rTeamInfo[state.iCurrTeam].id,
                {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}})
            .then(response => {
                console.log("Consents List:",response.data);
                commit('setConsents',response.data);
                state.bFetchingData=false;
            })
            .catch(error => {
                console.log("Error checking login:",error);
                state.bFetchingData=false;
            });
        }
    },
    fetchTeams({state,commit}) {
        state.bFetchingData=true;
        console.warn("Fetching team info");
        if(localStorage.getItem('token')!=null) {
            apiCall.get(hostForAPICalls+'/api/teams',
              {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}})
              .then(response => {
                console.warn("Teams:",response.data.teams);
                if(Array.isArray(response?.data?.teams) && typeof response.data.teams[0]?.id!='undefined') {
                    commit('setTeamInfo',response.data.teams);
                    console.log("Teams Saved!",response.data.teams);
                    //dispatch('fetchConsents');
                    //dispatch('fetchPatients');
                    //dispatch('fetchFullTeamInfo'); This is for the new UX, not needed here.
                }
            })
              .catch(error => {
                console.log("Error fetching teams:",error);
                state.bFetchingData=false;
            })
        }
    },
    fetchFullTeamInfo({state,commit}) {
        state.bFetchingData=true;
        console.warn("Fetching FULL team info");
        if(localStorage.getItem('token')!=null) {
            apiCall.get(hostForAPICalls+'/api/teams/fullDetail/'+state.rTeamInfo[state.iCurrTeam].id,
              {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}})
              .then(response => {
                console.log("FULL Team response:",response);
                if(response?.data?.status==true) {
                    commit('setPatientList',response.data.patients);
                    console.log("Patients Saved!",response.data.patients);
                    commit('setUserList',response.data.users);
                    console.log("Users Saved!",response.data.users);
                    commit('setProcedureList',response.data.procedures);
                    console.log("Procedures Saved!",response.data.procedures);
                }
                else {
                    console.log("Error fetching patients:",response);
                }
            })
              .catch(error => {
                console.log("Error fetching patients:",error);
                state.bFetchingData=false;
            })
            }
    },
    setUserPerms(state, sPerms) {
        state.commit('setUserPerms',sPerms);
    },
    setTeamInd({commit,dispatch},iInd) { 
        commit('setTeamInd',iInd);
        if(iInd>=0)
            dispatch('fetchFullTeamInfo');
    },
    //Fetch the tree of histories from the BE
    FetchHistoriesTree({state})                 { 
        return new Promise((resolve, reject) => {
            state.bFetchingData=true;
            apiCall.post(hostForAPICalls+'/php/history_tree_query_full.php')
            .then(response => 
            {
                //if(response.data.status==true) {
                    console.log("Histories Tree:",response.data);
                    //state.rAlarmsTree=response.data;
                    state.rHistTree=response.data;
                    state.bFetchingData=false;
                    if(typeof response.data !='undefined' && typeof response.data[0]?.location_id !='undefined' 
                        && response.data.findIndex(h=>h.location_id==state.CurrHistPathID)==-1) {
                        state.CurrHistPathID=response.data[0].location_id;
                    }
                    state.intFRefresh++;
                    resolve(response);
                    //dispatch('FetchHistItemsAtPath',state.CurrHistPathID);
                //} resolve(response);
            })
            .catch(function (error) {
                console.log("ERROR:",error);
                state.bFetchingData=false;
                reject(error)
            });
        });
    },
    //Pull History items in the current path
    /*FetchHistItemsAtPath({state, dispatch, getters, rootState, rootGetters},intTrg) { // eslint-disable-line no-unused-vars
        if(intTrg==-1 || typeof intTrg=='undefined')
            return [];
        return new Promise((resolve, reject) => {
            state.bFetchingData=true;
            var fTrg=new FormData();
            fTrg.set('location_id',intTrg);
            apiCall.post(hostForAPICalls+'/php/history_location_items_query.php',fTrg)
            .then(response => 
            {
                //if(response.data.status==true) {
                    //console.warn("Histories Items @("+intTrg+"):",response.data);
                    //state.rAlarmsTree=response.data;
                    state.rHistItems=response.data;
                    state.bFetchingData=false;
                    state.intFRefresh++;
                    resolve(response);
                //} resolve(response);
            })
            .catch(function (error) {
                console.log("ERROR:",error);
                state.bFetchingData=false;
                reject(error)
            });
        });
    },*/
    FetchData({state, dispatch, commit}) {// eslint-disable-line no-unused-vars
        if(state.bPollingData) {
            console.log("Previous data request still pending...");
            return false;
        }
        else {
            state.bPollingData=true;
            commit('setDtmLastDataPoll');
            //console.warn("Last Time poll is now:",state.dtmLastDataPoll);
        }
        var tNextPoll=2000;
        if(state.bLoggedOut===true)
            dispatch('users/signOut', {}, {root:true});
        dispatch('FetchPertInfo');
        dispatch('SetTime');
        if(state.CurrModule=='Fridges') {
            dispatch('FetchFridges').then(response => { // eslint-disable-line no-unused-vars
                //Set initial home if not set
                var y=0, sPerms=state.permissions,intFridgeInd=-1;
                for(y=0;y<sPerms.length;y++) {
                    if(sPerms[y].module_id=='fridges' || sPerms[y].module_id=='cold_storage')
                        intFridgeInd=y;
                }
                var intHomeId=(intFridgeInd!=-1 ? sPerms[intFridgeInd].mod_home : 1 );
                if(state.CurrFridgePathID==1 && state.CurrFridgePathID!=intHomeId) {
                    //console.warn("FORCE JUMP!",state.CurrFridgePathID,intHomeId);
                    var sTrg={};
                    sTrg['intPathID']=intHomeId;
                    sTrg['UserPerms']=state.permissions;
                    dispatch('JumpToFridgePathId',sTrg);
                }
                state.bFetchingData=false;
                state.bPollingData=false;
                setTimeout(function() {
                    //console.warn("FetchData scheuled again for:",tNextPoll);
                    dispatch('FetchData');
                }, tNextPoll);
            });
        }
        //console.log("CurrModule:",state.CurrModule);
        else if(state.CurrModule=='Alarms') {
          dispatch('FetchAlarms').then(response => { // eslint-disable-line no-unused-vars
            //Set initial home if not set
            var y=0, sPerms=state.permissions,intAlarmInd=-1;
            for(y=0;y<sPerms.length;y++) {
                if(sPerms[y].module_id=='fridges' || sPerms[y].module_id=='cold_storage')
                    intAlarmInd=y;
            }
            var intHomeId=(intAlarmInd!=-1 ? sPerms[intAlarmInd].mod_home : 1 );
            if(state.CurrAlarmPathID==1 && state.CurrAlarmPathID!=intHomeId) {
                console.warn("FORCE ALARM JUMP!",state.CurrAlarmPathID,intHomeId);
                var sTrg={};
                sTrg['intPathID']=intHomeId;
                sTrg['UserPerms']=state.permissions;
                dispatch('JumpToAlarmPathId',sTrg);
            }
            state.bFetchingData=false;
            state.bPollingData=false;
            setTimeout(function() {
                //console.warn("FetchData scheuled again for:",tNextPoll);
                dispatch('FetchData');
            }, tNextPoll);
        });
        }
        else if(state.CurrModule=='Histories') {
            state.bPollingData=false;
            //console.log("No need")
            /*dispatch('FetchHistoriesTree').then(response => { // eslint-disable-line no-unused-vars
                //Set initial home if not set
                var y=0, sPerms=state.permissions,intFridgeInd=-1;
                for(y=0;y<sPerms.length;y++) {
                    if(sPerms[y].module_id=='history' )
                        intFridgeInd=y;
                }
                var intHomeId=(intFridgeInd!=-1 ? sPerms[intFridgeInd].mod_home : 1 );
                if(state.CurrHistPathID==1 && state.CurrHistPathID!=intHomeId) {
                    console.warn("FORCE HISTORY JUMP!",state.CurrFridgePathID,intHomeId);
                    var sTrg={};
                    sTrg['intPathID']=intHomeId;
                    sTrg['UserPerms']=state.permissions;
                    dispatch('JumpToHistPathId',sTrg);
                }
                
            });*/
        }
        else if(state.CurrModule=='Graphics' || state.CurrModule=='graphicsView') {
            //console.log("Fetch some graphics data:",state.sCurrGraphicConf);
            var x,y,z,iInd,Form=new FormData(),rDataPoints=[],sPoint,txtTemp,sPayload;
            tNextPoll=1000;
            //console.warn("json_code:",state.sCurrGraphicConf.json_code);
            for(x=0;x<state.sCurrGraphicConf?.json_code?.updates.length;x++) {
                for(y=0;y<state.sCurrGraphicConf.json_code.updates[x].updates.length;y++) {
                    sPayload={};
                    if(state.sCurrGraphicConf.json_code.updates[x].updates[y].type=='SlotValueByPath' &&
                        (!state.sCurrGraphicConf.json_code.updates[x].updates[y].payload.controller_id ||
                            state.sCurrGraphicConf.json_code.updates[x].updates[y].payload.sedona_path==null  ))
                        console.log("Skipping invalid Sedona Object:",x,y,state.sCurrGraphicConf.json_code.updates[x].updates[y]);
                    else {
                        sPoint={};
                        sPoint.command=state.sCurrGraphicConf.json_code.updates[x].updates[y].command;
                        if(typeof state.sCurrGraphicConf.json_code?.updates[x]?.updates[y]?.payload =='undefined') {
                            //console.warn("Missing item:",state.sCurrGraphicConf.json_code.updates[x].updates[y]);
                            sPayload.controllerId=(typeof state.sCurrGraphicConf.json_code.updates[x].updates[y].controllerId !='undefined' ?
                                                    state.sCurrGraphicConf.json_code.updates[x].updates[y].controllerId : '' );
                            sPayload.slotId=(typeof state.sCurrGraphicConf.json_code.updates[x].updates[y].slotId !='undefined' ?
                                                    state.sCurrGraphicConf.json_code.updates[x].updates[y].slotId : -1 );
                            sPayload.sedona_path=(typeof state.sCurrGraphicConf.json_code.updates[x].updates[y].station !='undefined' ? 
                                                    'station:'+state.sCurrGraphicConf.json_code.updates[x].updates[y].station : 'station:' )+
                                                    "|"+
                                                 (typeof state.sCurrGraphicConf.json_code.updates[x].updates[y].slotId !="undefined" ?
                                                    "slot:"+state.sCurrGraphicConf.json_code.updates[x].updates[y].slotId : "slot:" );
                            sPoint.payload=sPayload;
                        }
                        else
                            sPoint.payload=state.sCurrGraphicConf.json_code.updates[x].updates[y].payload;
                        if(typeof state.sCurrGraphicConf.json_code.updates[x].updates[y].slotId!='undefined')
                            sPoint.payload.slot_id=state.sCurrGraphicConf.json_code.updates[x].updates[y].slotId;
                        if(typeof state.sCurrGraphicConf.json_code.updates[x].updates[y].controllerId!='undefined')
                            sPoint.payload.controller_id=state.sCurrGraphicConf.json_code.updates[x].updates[y].controllerId;
                        //sPoint.payload.path=state.sCurrGraphicConf.json_code.updates[x].updates[y].sedonaLocation;
                        txtTemp=JSON.stringify(sPoint);
                        if(typeof sPoint.command!='undefined') {
                            iInd=rDataPoints.findIndex(p=>JSON.stringify(p)==txtTemp);
                            if(iInd==-1)    //Check for duplicates
                                rDataPoints.push(sPoint);
                        }
                        else if (typeof sPoint.command=='undefined') { //This point is not needed here, it's handled by get_summary_of_many.php Data
                            //console.warn("Point missing command["+x+","+y+"]:",sPoint,state.sCurrGraphicConf.json_code.updates[x].updates[y]);
                        }
                    }
                }
            }
            for(x=0;x<state.sCurrGraphicConf?.json_code?.tables.length;x++) {
                for(y=0;y<state.sCurrGraphicConf?.json_code?.tables[x]?.cells.length;y++) {
                    for(z=0;z<state.sCurrGraphicConf?.json_code?.tables[x]?.cells[y]?.cells.length;z++) {
                        if(state.sCurrGraphicConf.json_code.tables[x].cells[y].cells[z].type=='SlotValueByPath' &&
                            (!state.sCurrGraphicConf.json_code.tables[x].cells[y].cells[z].payload.controller_id ||
                                state.sCurrGraphicConf.json_code.tables[x].cells[y].cells[z].payload.sedona_path==null  )) {
                            console.log("Skipping invalid Sedona Object:",x,y,state.sCurrGraphicConf.json_code.tables[x].cells[y].cells[z]);
                        }
                        else if(typeof state.sCurrGraphicConf.json_code.tables[x].cells[y].cells[z]?.calculator?.command!='undefined') {
                            sPoint={};
                            sPoint.command=state.sCurrGraphicConf.json_code.tables[x].cells[y].cells[z].calculator.command;
                            sPoint.payload=state.sCurrGraphicConf.json_code.tables[x].cells[y].cells[z].calculator.payload;
                            //console.warn("Working with:",state.sCurrGraphicConf.json_code.tables[x].cells[y].cells[z]);
                            sPoint.payload.slot_id=state.sCurrGraphicConf.json_code.tables[x].cells[y].cells[z].calculator.slotId;
                            sPoint.payload.controller_id=(typeof state.sCurrGraphicConf?.json_code?.tables[x]?.cells[y]?.cells[z]?.calculator?.slotId !='undefined' ?
                                state.sCurrGraphicConf?.json_code?.tables[x]?.cells[y]?.cells[z]?.calculator?.slotId : 
                                    (typeof state.sCurrGraphicConf.json_code.tables[x].cells[y].cells[z]?.payload!='undefined' ?
                                        state.sCurrGraphicConf.json_code.tables[x].cells[y].cells[z].payload : -1));
                            //sPoint.payload.path=state.sCurrGraphicConf.json_code.updates[x].updates[y].sedonaLocation;
                                txtTemp=JSON.stringify(sPoint);
                            iInd=rDataPoints.findIndex(p=>JSON.stringify(p)==txtTemp);
                            if(iInd==-1)
                                rDataPoints.push(sPoint);
                        }
                    }
                }
            }
            Form.set('points',JSON.stringify(rDataPoints));
            commit('setGraphicsDataReq',rDataPoints);
            commit('setGraphicsTime',Date.now());
            apiCall.post(hostForAPICalls+'/plugins/graphics/data_rego/registerData.php',Form)
            .then(response => 
            {
                state.bFetchingData=false;
                state.bPollingData=false;
                if(response.data.status==true) {
                    commit('setLatestGraphicsData',response.data.response);
                    //commit('updateGraphicsValues',response.data.response);
                }
                else
                {
                    console.log("Failed to fetch Graphics Data...");
                }
                setTimeout(function() {
                    //console.warn("FetchData scheuled again for:",tNextPoll);
                    dispatch('FetchData');
                }, tNextPoll);
            })
            .catch(function (error) {
                console.log("ERROR fetching Graphics Data:",error);
                state.bFetchingData=false;
                state.bPollingData=false;
                setTimeout(function() {
                    //console.warn("FetchData scheuled again for:",tNextPoll);
                    dispatch('FetchData');
                }, tNextPoll);
            });            
        }
        else if(state.CurrModule=='Network') {
            Form=new FormData();
            var rCs=[];
            for(x=0;x<state.rControllers.length;x++) {
                rCs.push(state.rControllers[x].controller_id);
            }
            Form.set('controllers',JSON.stringify(rCs));
            apiCall.post(hostForAPICalls+'/plugins/controllers/gettingPingInfo/controllerPingInfo.php',Form)
            .then(response => 
            {
                state.rControllers=response.data.controllers;
                state.bPollingData=false;
            })
            .catch(function(e) {
                console.error("controllerPingInfo.php errored:",e);
                state.bPollingData=false;
            });
        }
        else {  //We don't need to fetch any data, so reset Polling data
            state.bPollingData=false;
        }

    },
    FetchGraphicsSummaryData({commit}) {
        var x,y,Form=new FormData(),sPoint,rSummaryManyPoints=[];
        //console.warn("json_code:",state.sCurrGraphicConf.json_code);
        for(x=0;x<state.sCurrGraphicConf?.json_code?.updates.length;x++) {
            for(y=0;y<state.sCurrGraphicConf.json_code.updates[x].updates.length;y++) {
                var sUpd=state.sCurrGraphicConf.json_code.updates[x].updates[y];
                if(typeof sUpd?.command=='undefined' && sUpd.calculator.batchURI=='plugins/history/php/get_summary_of_many.php') 
                {
                    sPoint={};
                    sPoint.uid=sUpd?.guid;
                    sPoint.time_range=sUpd.calculator?.timeRangeId;
                    sPoint.type=(sUpd.calculator?.type=="BooleanHistory" ? "bool" : "float");
                    sPoint.op=sUpd.calculator?.summaryType;
                    sPoint.id=sUpd.calculator?.historyId;
                    sPoint.val_type=sUpd.calculator.valType;
                    rSummaryManyPoints.push(sPoint);
                }
            }
        }
        console.warn("GraphicsSummaryData polled with:",rSummaryManyPoints);
        if(rSummaryManyPoints.length>0) {
            Form.set('points',JSON.stringify(rSummaryManyPoints));
            apiCall.post(hostForAPICalls+'/plugins/history/php/get_summary_of_many.php',Form)
            .then(response => 
            {
                if(response.data.status==true) {
                    commit('setLatestSummaryGraphicsData',response.data.response);
                    //commit('updateGraphicsValues',response.data.response);
                }
                else
                {
                    console.log("Failed to fetch Summary Graphics Data...");
                }
            })
            .catch(function (error) {
                console.log("ERROR fetching Summary Graphics Data:",error);
            });            

        }

    },
    FetchGraphicsTree({commit}) {
        commit('setBFetchingData',true);
        return apiCall.get(hostForAPICalls+'/php/graphicsTreeQuery.php')
        .then(response => 
        {
            state.bFetchingData=false;
            if(response.data.status==true) {
                console.warn("Response for FetchGraphicsTree:",response.data.response);
                commit('setGraphicsTree',response.data.response);
            }
            else
            {
                console.log("Failed to fetch Graphics Tree...");
            }
        })
        .catch(function (error) {
            console.log("ERROR fetching Graphics Tree:",error);
            commit('setBFetchingData',false);
        });
    },
    toggleApplsMute(state)              { state.commit('toggleApplsMute');                  },
    setFridgeView(state,txtView)        { state.commit('setFridgeView',txtView);            },
    setGraphicsView(state,txtView)        { state.commit('setGraphicsView',txtView);            },
    setAlarmView(state,txtView)         { state.commit('setAlarmView',txtView);            },
    setHistView(state,txtView)         { state.commit('setHistView',txtView);            },
    setNetView(state,txtView)         { state.commit('setNetView',txtView);            },
    setApplsMute(state, bState)         { state.commit('toggleApplsMute',bState);           },
    setCustFridgeFields(state, rFields) { state.commit('setCustFridgeFields',rFields);      },
    SetSuccess(state, bSucc)            { state.commit('SetSuccess',bSucc);                 },
    ReplaceFridges(state,rFridges)      { state.commit('ReplaceFridges',rFridges);          },
    JumpToFridgePathId({commit,rootGetters},intPathID) { 
        var sPayLoad={};
        sPayLoad['intPathID']=intPathID;
        sPayLoad['UserPerms']=rootGetters['users/getUserPerms'];
        commit('JumpToFridgePathId',sPayLoad);
    },
    SetFridgePath({commit,rootGetters},txtPath)        { 
        //console.warn("Navigating to:",txtPath);
        var sPayLoad={};
        sPayLoad['txtPath']=txtPath;
        sPayLoad['UserPerms']=rootGetters['users/getUserPerms'];
        commit('SetFridgePath',sPayLoad);            
    },
    JumpToAlarmPathId({commit,rootGetters},intPathID) { 
        var sPayLoad={};
        sPayLoad['intPathID']=intPathID;
        sPayLoad['UserPerms']=rootGetters['users/getUserPerms'];
        commit('JumpToAlarmPathId',sPayLoad);
    },
    SetAlarmPath({commit,rootGetters},txtPath)        { 
        var sPayLoad={};
        sPayLoad['txtPath']=txtPath;
        sPayLoad['UserPerms']=rootGetters['users/getUserPerms'];
        commit('SetAlarmPath',sPayLoad);            
    },
    JumpToHistPathId({commit,rootGetters},intPathID) { 
        var sPayLoad={};
        sPayLoad['intPathID']=intPathID;
        sPayLoad['UserPerms']=rootGetters['users/getUserPerms'];
        commit('JumpToHistPathId',sPayLoad);
    },
    SetHistPath({commit,rootGetters},txtPath)        { 
        //console.warn("SetHistPath received txtPath:",txtPath);
        var rT=rootGetters['consentic/GetHistTree'];
        var iFind=rT.findIndex(h => (h.folder_path==txtPath));
        if(iFind==-1) {
            console.warn("Couldn't find path:",txtPath);
            return false;
        }
        var sPayLoad={};
        sPayLoad['txtPath']=txtPath;
        sPayLoad['UserPerms']=rootGetters['users/getUserPerms'];
        commit('SetHistPath',sPayLoad);            
        //dispatch('FetchHistItemsAtPath',state.CurrAlarmPathID);
    },
    JumpToNetPathId({commit,rootGetters},intPathID) { 
        var sPayLoad={};
        sPayLoad['intPathID']=intPathID;
        sPayLoad['UserPerms']=rootGetters['users/getUserPerms'];
        commit('JumpToNetPathId',sPayLoad);
    },
    SetNetPath({commit,rootGetters},txtPath)        { 
        //console.warn("SetNetPath received txtPath:",txtPath);
        var rT=rootGetters['consentic/GetNetTree'];
        var iFind=rT.findIndex(h => (h.folder_path==txtPath));
        if(iFind==-1) {
            console.warn("Couldn't find path:",txtPath);
            return false;
        }
        var sPayLoad={};
        sPayLoad['txtPath']=txtPath;
        sPayLoad['UserPerms']=rootGetters['users/getUserPerms'];
        commit('SetNetPath',sPayLoad);            
        //dispatch('FetchNetItemsAtPath',state.CurrAlarmPathID);
    },
    SetGraphPath({commit,rootGetters},txtPath)        { 
        var sPayLoad={};
        sPayLoad['txtPath']=txtPath;
        sPayLoad['UserPerms']=rootGetters['users/getUserPerms'];
        commit('SetGraphPath',sPayLoad);
    },
    FetchFridges(state)                 { state.commit('FetchFridges');                     },
    FetchAlarms(state)                  { state.commit('FetchAlarms');                     },
    GetUsers(state)                     { state.commit('GetUsers');                         },
    SetAlarmSearch(state,strSearch)     { state.commit('SetAlarmSearch',strSearch);        },
    SetFridgeSearch(state,strSearch)    { state.commit('SetFridgeSearch',strSearch);        },
    SetUserSearch(state,strSearch)      { state.commit('SetUserSearch',strSearch);          },
    ClearFridgeFilters(state)           { state.commit('ClearFridgeFilters');               },
    SetAlarmFilter(state)               { state.commit('SetAlarmFilter');                   },
    ToggleActiveAlarmFilter(state)      { state.commit('ToggleActiveAlarmFilter');                },
    ToggleAlarmsAckFilter(state)        { state.commit('ToggleAlarmsAckFilter');                  },
    ToggleAlarmsUnresFilter(state)      { state.commit('ToggleAlarmsUnresFilter');                  },
    ToggleAlarmFilter(state)            { state.commit('ToggleAlarmFilter');                },
    ToggleAckFilter(state)              { state.commit('ToggleAckFilter');                  },
    ToggleUnresFilter(state)            { state.commit('ToggleUnresFilter');                  },
    SetTime({commit,rootGetters})       { commit('setTime',rootGetters['users/getTZ']);     },
    setCurrModule({commit},module)      { commit('setModule',module);                       },
    FetchControllers({commit})          { commit('FetchControllers');                         },
    PlayBeep(state) {
        if(!state.bMuteFridges)
          state.theBeep.play();
    },
    FetchTrees({commit}) {
        state.bFetchingData=true;
        apiCall.get(hostForAPICalls+'/plugins/useradmin/userTrees.php')
        .then(response => 
        {
            console.log("Response for FetchTrees:",response.data.response);
            commit('setTrees',response.data.response);
        })
        .catch(function (error) {
            console.log("ERROR Fetching Trees Info:",error);
            state.bFetchingData=false;
        });
    },
    FetchPertInfo({commit}) {
        state.bFetchingData=true;
        apiCall.get(hostForAPICalls+'/php/getAllPertinentInformation.php?time=1&last_update_time=1&weather=1&fridges=1&controllers=1&alarms=1&history=1')
        .then(response => 
        {
            //console.log("Response for PertInfo:",response.data.response);
            commit('setPertInfo',response.data.response);
        })
        .catch(function (error) {
            if(error?.response?.status==401) {
                state.bLoggedOut=true;
                //console.warn("bLoggedOut is now:",state.bLoggedOut);
            }
            console.log("ERROR updating Pertinent Info:",error);
            state.bFetchingData=false;
        });
    },   
    FetchModuleList({commit}) {
        state.bFetchingData=true;
        apiCall.get(hostForAPICalls+'/core/getInfo.php?operation=modules_list_query')
        .then(response => 
        {
            console.log("Response for ModuleList:",response.data);
            //Quick fix for Mainten-->a<--nce Log
            var temp=response.data.findIndex(function(element) { return element.name=="Maintenence Log";  });
            if(temp!=-1)
                response.data[temp].name="Maintenance Log";
            commit('setModuleList',response.data);
        })
        .catch(function (error) {
            console.log("ERROR Fetching Modules:",error);
            state.bFetchingData=false;
        });
    },
};

const mutations = {
    setTeamInd(state,iInd) {state.iCurrTeam=iInd},
    setPatientList(state,rPatients) {state.rPatients=rPatients},
    setUserList(state,rUsers) {state.rUsers=rUsers},
    setProcedureList(state,rProcedures) {state.rProcedures=rProcedures},
    setConsents(state,rConsents) { state.rConsents=rConsents},
    setTeamInfo(state,sTInfo) { state.rTeamInfo=sTInfo; state.iCurrTeam=-1;},
    setKits(state,rKits) { state.rKits=rKits},
    setControllerTypes(state, rTypes) { state.rControllerTypes=rTypes },
    setBFetchingData(state, bFetch) { state.bFetchingData=bFetch;},
    setGraphicsCSS(state, sStyles) { 
        var x,sNewStyles={},sNewStyle={};
        //console.warn("Graphics Styles received:",sStyles);
        for(x=0;x<sStyles.length;x++) {
            sNewStyle={};
            sNewStyle['attributes']=sStyles[x]['attributes'];
            sNewStyle['classes']=sStyles[x]['classes'];
            //console.warn("Adding Style:",sStyles[x].name,sNewStyles);
            sNewStyles[sStyles[x].name]=sNewStyle;
        }
        //console.warn("Final Styles:",sNewStyles);
        state.sGlobalGraphicsStyles=sNewStyles;
    },
    setDtmLastDataPoll(state) {
        state.dtmLastDataPoll=Date.now();
        localStorage.setItem('PollTime',state.dtmLastDataPoll);
        //console.warn("Seriously it is now:",state.dtmLastDataPoll);
    },
    setGraphicsDataReq(state,rDataPoints) {
        state.rGraphicsDataReq=rDataPoints;
    },
    setLatestGraphicsData(state,rDataVals) {
        state.rLatestGraphicsData=rDataVals;
    },
    setLatestSummaryGraphicsData(state,rDataVals) {
        state.rLatestSummaryGraphicsData=rDataVals;
    },
    setGraphicsTime(state,tTime) {
        state.tLastGraphics=tTime;
    },
    UpdateGraphic(state,sGraphic) {
        state.sCurrGraphicConf=sGraphic;
        if(typeof state.sCurrGraphicConf.html_code!='undefined') { //Parse html and instantiate objects
            //console.warn("Parsing HTML:",state.sCurrGraphicConf.html_code);
            var tStr=state.sCurrGraphicConf.html_code;
            //var testStr=tStr;
            var x,y,z,rDivs=tStr.split('<div '),rProps=[],rProp,rStyles=[],sStyle={'attr':'','val':''};
            var sPropSet={},innerHTML='';
            //Pre-parse any id's with Updates to Text so they can be blanked out  
            var rUpdTexts=[];
            for(x=0;x<state.sCurrGraphicConf.json_code.updates.length;x++) {
                for(y=0;y<state.sCurrGraphicConf.json_code.updates[x].updates.length;y++) {
                    if(state.sCurrGraphicConf.json_code.updates[x].updates[y].attribute=='Text')
                        rUpdTexts.push(state.sCurrGraphicConf.json_code.updates[x].id);
                }
            }
            //console.warn("rUpdTexts:",rUpdTexts);
                //console.warn("rDivs:",rDivs);
            state.rGraphicObjs=[];
            var rTempNewGraphicsObs=[],tClass='',iJsonCodeIndex=-1;
            for(x=1;x<rDivs.length;x++) {
                tClass='';
                iJsonCodeIndex=-1;
                sPropSet={};
                innerHTML='';
                tStr=rDivs[x].indexOf('>');
                //console.warn("Parsing ["+x+"]:",tStr,rDivs[x].length,rDivs[x].length-tStr-6,rDivs[x]);
                innerHTML=(rDivs[x].substring(rDivs[x].length-6,rDivs[x].length)=="</div>" ? 
                                rDivs[x].substring(tStr+1,rDivs[x].length-6) : 
                                rDivs[x].substring(tStr+1,rDivs[x].length));
                rDivs[x]=rDivs[x].substring(0,tStr);
                //console.warn("Parsing2 ["+x+"]:",rDivs[x]);
                //console.warn("Parsing3 ["+x+"]:",innerHTML);
                rProps=rDivs[x].split('" ');
                //Parse Style Properties from the Div
                for(y=0;y<rProps.length;y++) {
                    /*if(x==rDivs.length-1) {
                        console.warn("Found props["+y+"]:",rProps[y]);
                    }*/
                    rProp=rProps[y].split('=');
                    if(rProp.length > 1) {
                        if(rProp[0]=='style') {
                            rStyles=rProp[1].replaceAll('\\','').replaceAll('"','').split(":");
                            sPropSet[rProp[0]]=[];
                            for(z=0;z<rStyles.length;z++){
                                sStyle={'attr':'','val':''};
                                if(z==0) {//first style=rStyles;
                                    sPropSet[rProp[0]][z]=sStyle;
                                    sPropSet[rProp[0]][z].attr=rStyles[z].trim();
                                }else if(z==rStyles.length-1) {//last style
                                    sPropSet[rProp[0]][z-1].val=rStyles[z].replaceAll(';','').trim();
                                }
                                else {
                                    sPropSet[rProp[0]][z]=sStyle;
                                    tStr=rStyles[z].split(';');
                                    sPropSet[rProp[0]][z-1].val=(tStr.length>0 ? tStr[0].trim() : '');
                                    sPropSet[rProp[0]][z].attr=(tStr.length>1 ? tStr[1].trim() : '');
                                }
                            }
                        }
                        else  {
                            if(rProp[0]=="class") {
                                tClass=rProp[1].replaceAll('"','');
                            }
                            sPropSet[rProp[0]]=rProp[1].replaceAll('"','');
                        }
                    }
                }
                if(tClass=='PanelHeaderInfo') { //Handler for a multi-Div component
                    sPropSet.innerHTML=innerHTML+'<div '+rDivs[x+1]+'<div '+rDivs[x+2]+'<div '+rDivs[x+3];
                    x+=3;
                }
                else if(tClass=='CompressorReverseValve') { //Handler for a mult-Div component
                    sPropSet.innerHTML=innerHTML+'<div '+rDivs[x+1]+'<div '+rDivs[x+2]+'<div '+rDivs[x+3]+'<div '+rDivs[x+4]+'<div '+rDivs[x+5]+'<div '+rDivs[x+6];
                    x+=6;
                }
                else {
                    sPropSet.innerHTML=(rUpdTexts.findIndex(t=>t==sPropSet.id)==-1 ? innerHTML : '--');
                }

                //Looper to check for falseResult in json_code which would override innerHTML for a component
                iJsonCodeIndex=sGraphic?.json_code?.updates.findIndex(u=>u.id==sPropSet.id);
                if(iJsonCodeIndex!=-1) {
                    var sUpd;
                    console.log("Update defined for:",sPropSet.id,iJsonCodeIndex,sGraphic.json_code.updates[iJsonCodeIndex]);
                    for(y=0;y<sGraphic.json_code.updates[iJsonCodeIndex].updates.length;y++) {
                        sUpd=sGraphic.json_code.updates[iJsonCodeIndex].updates[y];
                        //We want everything that has a falseResult to be the default displayed item
                        switch(sUpd.attribute) {
                            case "Image":
                                if(typeof sUpd?.falseResult!='undefined') {
                                    sPropSet.src='/'+sUpd.falseResult;
                                    console.log("Image set false result:",sPropSet.src);
                                }
                            break;
                            default:
                            break;
                        }
                    }
                }
                /*if(sPropSet.id=='Label42') {
                    console.warn("PropSet:",sPropSet,testStr);
                }*/
                rTempNewGraphicsObs.push(sPropSet);
            }
        }
        state.rGraphicObjs=rTempNewGraphicsObs;
        //state.intFRefresh++;
        console.warn("Grpahics Objects:",state.rGraphicObjs,state.sCurrGraphicConf.json_code);
    },
    setSlotTypes(state,rTypes)                 { state.rSlotTypes=rTypes;},
    toggleAlarmsMute(state)             { state.bAlarmsMuted=!state.bAlarmsMuted;   },
    setUserPerms(state, sPerms)         { state.permissions=sPerms      },
    setFridgeView(state, txtView)       { state.currFridgeView=txtView;  },
    setGraphicsView(state, txtView)       { state.currGraphicsView=txtView;  },
    setAlarmView(state, txtView)       { state.currAlarmView=txtView;  },
    setHistView(state, txtView)       { state.currHistView=txtView;  },
    setNetView(state, txtView)       { state.currNetView=txtView;  },
    setGraphicsTree(state,rGTree)              { 
        state.rGraphicsTree=rGTree; 
        console.warn("Graphics Tree received:",rGTree); 
    },
    toggleApplsMute(state)              { state.bApplsMuted=!state.bApplsMuted;     },
    setApplsMute(state, bState)         { state.bApplsMuted=bState;         },
    SetSuccess(state, bSucc) {state.bSuccess=bSucc;},
    setModule(state, module) { state.CurrModule=module},
    setModuleList(state, rModuleList) {state.rModuleList=rModuleList},
    setPertInfo(state,rPertInfo) {
        state.rDashInfo=rPertInfo;
        state.bFetchingData=false;
    },
    setCustFridgeFields(state,rFields) { state.custom_fields=rFields },
    FetchAlarms(state) {
        return new Promise((resolve, reject) => {
        state.bFetchingData=true;
        apiCall.post(hostForAPICalls+'/plugins/alarms/php/alarm_tree_query_full.php')
            .then(response => 
            {
                //if(response.data.status==true) {
                    console.log("Alarms Tree:",response.data);
                    //state.rAlarmsTree=response.data;
                    state.rAlarms=response.data;
                    state.bFetchingData=false;
                    state.intFRefresh++;
                //} 
                resolve(response);
            })
            .catch(function (error) {
                console.log("ERROR:",error);
                if(error?.response?.status==401) {//User session timed out, redirect to login
                    state.bLoggedOut=true;
                }
                state.bFetchingData=false;
                reject(error)
            });
        });
    },
    FetchFridges(state) {
        state.bFetchingData=true;
        return apiCall.post(hostForAPICalls+'/plugins/fridges/php/getEverything.php')
            .then(response => 
            {
                if(response.data.status==true) {
                    console.log("Fridge Tree:",response.data.response.tree);
                    state.rFridges=response.data.response.tree;
                    state.custom_fields=response.data.response.custom_fields;
                    state.ap_settings=response.data.response.ap_settings;
                    state.breach_types=response.data.response.breach_types;
                    state.bFetchingData=false;
                    state.intFRefresh++;
                }
                response.errorCode=401;
                return response;
            })
            .catch(function (reject) {
                console.log("ERROR:",reject);
                if(reject?.response?.status==401) {//User session timed out, redirect to login
                    state.bLoggedOut=true;
                }

                state.bFetchingData=false;
                return reject;
            });
    },
    setTrees(state,rTrees) {
        state.rAlarmsTree=(typeof rTrees?.alarm_tree!='undefined' ? rTrees.alarm_tree : []);
        state.rControllerTree=(typeof rTrees?.controllers_tree!='undefined' ? rTrees.controllers_tree : []);
        state.rFridgesTree=(typeof rTrees?.fridges_tree!='undefined' ? rTrees.fridges_tree : []);
        //state.rGraphicsTree=(typeof rTrees?.graphics_tree!='undefined' ? rTrees.graphics_tree : []);
        state.rHistoryTree=(typeof rTrees?.history_tree!='undefined' ? rTrees.history_tree : []);
        state.rMaintLogTree=(typeof rTrees?.maint_tree!='undefined' ? rTrees.maint_tree : []);
        state.rTennantTree=(typeof rTrees?.tennant_tree!='undefined' ? rTrees.tennant_tree : []);
        console.log("Fridges tree:",state.rFridgesTree);
        //console.log("Graphics tree:",state.rGraphicsTree);
        //BRR Temporary! set the graphics Tree for the OLD js we use in graphics
        //window.getGraphicsTree(); 
    },
    setTime(state, TZ) {
        var TZ2=( typeof TZ!='undefined' && TZ!='' ? TZ : 'Australia/Brisbane');
        var date = new Date();
        date=new Date(Date.parse(date.toLocaleString("en-US", {timeZone: TZ2})));
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        let weekDay = date.getDay();
        state.currMeri = hours > 12 ? 'PM' : 'AM';
        state.currHours = hours <= 12 ? String(hours) : String(hours-12);
        state.currMinutes = String(minutes).padStart(2, 0);
        state.currSeconds = String(seconds).padStart(2, 0);
        state.currDay=String(date.getDate()).padStart(2, 0);
        state.currMonth=String(date.getMonth()+1).padStart(2,'0');
        state.currYear=date.getFullYear();
        switch(weekDay) {
            case 0: state.currWDay='Sun'; break;
            case 1: state.currWDay='Mon'; break;
            case 2: state.currWDay='Tue'; break;
            case 3: state.currWDay='Wed'; break;
            case 4: state.currWDay='Thu'; break;
            case 5: state.currWDay='Fri'; break;
            case 6: state.currWDay='Sat'; break;
        }
        //console.warn("Time stuff is now:",state.currYear,state.currMonth,state.currDay,weekDay,state.currWDay,state.currHours,state.currMinutes,state.currSeconds,state.currMeri);
      },
    ClearFridgeFilters(state) {
        state.bFridgeAlarm=false;
        state.bFridgeUnacknowledged=false;
    },
    SetAlarmFilter(state) {
        state.bFridgeAlarm=true;
        state.bFridgeUnacknowledged=false;
    },
    ToggleAlarmFilter(state) {
        if(state.bFridgeAlarm) {
            state.bFridgeAlarm=false;
        }
        else {
            state.bFridgeAlarm=true;
            state.bFridgeUnacknowledged=false;
            state.bFridgeUnresolved=false;
        }
    },
    ToggleAckFilter(state) {
        if(state.bFridgeUnacknowledged) 
            state.bFridgeUnacknowledged=false;
        else {
            state.bFridgeUnacknowledged=true;
            state.bFridgeAlarm=false;
            state.bFridgeUnresolved=false;
        }
    },
    ToggleUnresFilter(state) {
        if(state.bFridgeUnresolved) 
            state.bFridgeUnresolved=false;
        else {
            state.bFridgeUnresolved=true;
            state.bFridgeUnacknowledged=false;
            state.bFridgeAlarm=false;
        }
    },
    ClearAlarmFilters(state) {
        state.bAlarmActive=false;
        state.bAlarmUnacknowledged=false;
    },
    SetActiveAlarmFilter(state) {
        state.bAlarmActive=true;
        state.bAlarmUnacknowledged=false;
    },
    ToggleActiveAlarmFilter(state) {
        if(state.bAlarmActive) {
            state.bAlarmActive=false;
        }
        else {
            state.bAlarmActive=true;
            state.bAlarmUnacknowledged=false;
            state.bAlarmUnresolved=false;
        }
    },
    ToggleAlarmsAckFilter(state) {
        if(state.bAlarmUnacknowledged) 
            state.bAlarmUnacknowledged=false;
        else {
            state.bAlarmUnacknowledged=true;
            state.bAlarmActive=false;
            state.bAlarmUnresolved=false;
        }
    },
    ToggleAlarmsUnresFilter(state) {
        if(state.bAlarmUnresolved) 
            state.bAlarmUnresolved=false;
        else {
            state.bAlarmUnresolved=true;
            state.bAlarmUnacknowledged=false;
            state.bAlarmActive=false;
        }
    },
    ReplaceFridges(state,rFridges) {
        state.rCurrFridges=rFridges;
    },
    JumpToFridgePathId(state, sPayLoad) {
        var y=0,sNewPath;
        var txtPath=sPayLoad.intPathID, sPerms=sPayLoad.UserPerms,intFridgeInd=-1;
        //txtPath will NOT get just the number, but it .intPathID works??? WT#??
        if(typeof txtPath.intPathID !='undefined')
            txtPath=txtPath.intPathID;
        for(y=0;y<sPerms.length;y++) {
            if(sPerms[y].module_id=='fridges' || sPerms[y].module_id=='cold_storage')
                intFridgeInd=y;
        }
        var intHomeId=(intFridgeInd!=-1 ? sPerms[intFridgeInd].mod_home : 1 ),txtHomePath;
        var FridgeInd=state.rFridges.findIndex(f => (f.id==intHomeId));
        if(FridgeInd!=-1)
            txtHomePath=state.rFridges[FridgeInd].path;

        //console.warn("RFridges:",txtPath,JSON.stringify(state.rFridges));
        if(isNaN(txtPath)) {
            //console.log("Searching for path=",txtPath);
            sNewPath=state.rFridges.find(cFridge => cFridge?.path === txtPath);
        }
        else {
            //console.log("Searching for id=",txtPath);
            sNewPath=state.rFridges.find(cFridge => cFridge?.id == txtPath);
        }
        //console.log("sNewPath:",sNewPath);
        if(typeof sNewPath=='undefined') {
            console.log("Could not Jump to Path, Fridges list may not yet be ready.");
            return false;
        }
        //console.log("Trying to get sNewPath:",sNewPath,txtPath,state.rFridges);
        state.CurrFridgePathID=sNewPath.id;
        state.CurrFridgePath=sNewPath.path;
        state.rFridgeExpPaths=[];
        state.rFridgeExpIDs=[];
        //console.log("Entering loop?",sNewPath.id,sNewPath.parent,sPerms);
        while(typeof sNewPath?.id !='undefined' && sNewPath.id!=1 && sNewPath.parent!=intHomeId && sNewPath.parent!=null && y<42) {
            //console.log("sNewPath is now ("+y+"):",JSON.stringify(sNewPath));
            if(sNewPath.id!=intHomeId) {
                state.rFridgeExpPaths.unshift(sNewPath.path);
                state.rFridgeExpIDs.unshift(sNewPath.id);
                sNewPath=state.rFridges.find(cFridge => cFridge?.id === sNewPath.parent);
            }
            y++;
        }
        state.rFridgeExpPaths.unshift(txtHomePath);
        state.rFridgeExpIDs.unshift(intHomeId);
        console.log("Final state arrays:",state.rFridgeExpIDs,state.rFridgeExpPaths);
    },
    JumpToAlarmPathId(state, sPayLoad) {
        var y=0,sNewPath;
        var txtPath=sPayLoad.intPathID, sPerms=sPayLoad.UserPerms,intAlarmInd=-1;
        //txtPath will NOT get just the number, but it .intPathID works??? WT#??
        if(typeof txtPath.intPathID !='undefined')
            txtPath=txtPath.intPathID;
        for(y=0;y<sPerms.length;y++) {
            if(sPerms[y].module_id=='alarms')
                intAlarmInd=y;
        }
        var intHomeId=(intAlarmInd!=-1 ? sPerms[intAlarmInd].mod_home : 1 ),txtHomePath;
        var AlarmInd=state.rAlarms.findIndex(f => (f.id==intHomeId));
        if(AlarmInd!=-1)
            txtHomePath=state.rAlarms[AlarmInd].path;

        //console.warn("RAlarms:",txtPath,JSON.stringify(state.rAlarms));
        if(isNaN(txtPath)) {
            //console.log("Searching for path=",txtPath);
            sNewPath=state.rAlarms.find(cAlarm => cAlarm?.path === txtPath);
        }
        else {
            //console.log("Searching for id=",txtPath);
            sNewPath=state.rAlarms.find(cAlarm => cAlarm?.id == txtPath);
        }
        //console.log("sNewPath:",sNewPath);
        if(typeof sNewPath=='undefined') {
            console.log("Could not Jump to Path, Alarms list may not yet be ready.");
            return false;
        }
        //console.log("Trying to get sNewPath:",sNewPath,txtPath,state.rAlarms);
        state.CurrAlarmPathID=sNewPath.id;
        state.CurrAlarmPath=sNewPath.path;
        state.rAlarmExpPaths=[];
        state.rAlarmExpIDs=[];
        //console.log("Entering loop?",sNewPath.id,sNewPath.parent,sPerms);
        while(typeof sNewPath?.id !='undefined' && sNewPath.id!=1 && sNewPath.parent!=intHomeId && sNewPath.parent!=null && y<42) {
            //console.log("sNewPath is now ("+y+"):",JSON.stringify(sNewPath));
            if(sNewPath.id!=intHomeId) {
                state.rAlarmExpPaths.unshift(sNewPath.path);
                state.rAlarmExpIDs.unshift(sNewPath.id);
                sNewPath=state.rAlarms.find(cAlarm => cAlarm?.id === sNewPath.parent);
            }
            y++;
        }
        state.rAlarmExpPaths.unshift(txtHomePath);
        state.rAlarmExpIDs.unshift(intHomeId);
        console.log("Final state arrays:",state.rAlarmExpIDs,state.rAlarmExpPaths);
    },
    JumpToHistPathId(state, sPayLoad) {
        var y=0,sNewPath;
        var txtPath=sPayLoad.intPathID, sPerms=sPayLoad.UserPerms,intAlarmInd=-1;
        //txtPath will NOT get just the number, but it .intPathID works??? WT#??
        if(typeof txtPath.intPathID !='undefined')
            txtPath=txtPath.intPathID;
        for(y=0;y<sPerms.length;y++) {
            if(sPerms[y].module_id=='alarms')
                intAlarmInd=y;
        }
        var intHomeId=(intAlarmInd!=-1 ? sPerms[intAlarmInd].mod_home : 1 ),txtHomePath;
        var HistoriesInd=state.rHistories.findIndex(f => (f.id==intHomeId));
        if(HistoriesInd!=-1)
            txtHomePath=state.rHistories[HistoriesInd].path;

        //console.warn("RHistoriess:",txtPath,JSON.stringify(state.rHistories));
        if(isNaN(txtPath)) {
            //console.log("Searching for path=",txtPath);
            sNewPath=state.rHistories.find(cHistory => cHistory?.path === txtPath);
        }
        else {
            //console.log("Searching for id=",txtPath);
            sNewPath=state.rHistories.find(cHistory => cHistory?.id == txtPath);
        }
        //console.log("sNewPath:",sNewPath);
        if(typeof sNewPath=='undefined') {
            console.log("Could not Jump to Path, Historiess list may not yet be ready.");
            return false;
        }
        //console.log("Trying to get sNewPath:",sNewPath,txtPath,state.rHistories);
        state.CurrHistPathID=sNewPath.id;
        state.CurrHistPath=sNewPath.path;
        state.rHistExpPaths=[];
        state.rHistExpIDs=[];
        //console.log("Entering loop?",sNewPath.id,sNewPath.parent,sPerms);
        while(typeof sNewPath?.id !='undefined' && sNewPath.id!=1 && sNewPath.parent!=intHomeId && sNewPath.parent!=null && y<42) {
            //console.log("sNewPath is now ("+y+"):",JSON.stringify(sNewPath));
            if(sNewPath.id!=intHomeId) {
                state.rHistExpPaths.unshift(sNewPath.path);
                state.rHistExpIDs.unshift(sNewPath.id);
                sNewPath=state.rHistories.find(cHistory => cHistory?.id === sNewPath.parent);
            }
            y++;
        }
        state.rHistExpPaths.unshift(txtHomePath);
        state.rHistExpIDs.unshift(intHomeId);
        console.log("Final History arrays:",state.rHistExpIDs,state.rHistExpPaths);
    },
    JumpToNetPathId(state, sPayLoad) {
        var y=0,sNewPath;
        var txtPath=sPayLoad.intPathID, sPerms=sPayLoad.UserPerms,intAlarmInd=-1;
        //txtPath will NOT get just the number, but it .intPathID works??? WT#??
        if(typeof txtPath.intPathID !='undefined')
            txtPath=txtPath.intPathID;
        for(y=0;y<sPerms.length;y++) {
            if(sPerms[y].module_id=='alarms')
                intAlarmInd=y;
        }
        var intHomeId=(intAlarmInd!=-1 ? sPerms[intAlarmInd].mod_home : 1 ),txtHomePath;
        var NetoriesInd=state.rNetories.findIndex(f => (f.id==intHomeId));
        if(NetoriesInd!=-1)
            txtHomePath=state.rNetories[NetoriesInd].path;

        //console.warn("RNetoriess:",txtPath,JSON.stringify(state.rNetories));
        if(isNaN(txtPath)) {
            //console.log("Searching for path=",txtPath);
            sNewPath=state.rNetories.find(cNetory => cNetory?.path === txtPath);
        }
        else {
            //console.log("Searching for id=",txtPath);
            sNewPath=state.rNetories.find(cNetory => cNetory?.id == txtPath);
        }
        //console.log("sNewPath:",sNewPath);
        if(typeof sNewPath=='undefined') {
            console.log("Could not Jump to Path, Netoriess list may not yet be ready.");
            return false;
        }
        //console.log("Trying to get sNewPath:",sNewPath,txtPath,state.rNetories);
        state.CurrNetPathID=sNewPath.id;
        state.CurrNetPath=sNewPath.path;
        state.rNetExpPaths=[];
        state.rNetExpIDs=[];
        //console.log("Entering loop?",sNewPath.id,sNewPath.parent,sPerms);
        while(typeof sNewPath?.id !='undefined' && sNewPath.id!=1 && sNewPath.parent!=intHomeId && sNewPath.parent!=null && y<42) {
            //console.log("sNewPath is now ("+y+"):",JSON.stringify(sNewPath));
            if(sNewPath.id!=intHomeId) {
                state.rNetExpPaths.unshift(sNewPath.path);
                state.rNetExpIDs.unshift(sNewPath.id);
                sNewPath=state.rNetories.find(cNetory => cNetory?.id === sNewPath.parent);
            }
            y++;
        }
        state.rNetExpPaths.unshift(txtHomePath);
        state.rNetExpIDs.unshift(intHomeId);
        console.log("Final Netory arrays:",state.rNetExpIDs,state.rNetExpPaths);
    },
    SetGraphPath(state, sPayLoad) {
        var txtPath=sPayLoad.txtPath.path;
        //var sGraph=sPayLoad.sGraph;
        var sPerms=sPayLoad.UserPerms, intGraphInd;
        for(y=0;y<sPerms.length;y++) {
            if(sPerms[y].module_id=='graphics')
                intGraphInd=y;
        }
        var intHomeId=(intGraphInd!=-1 ? parseInt(sPerms[intGraphInd].mod_home) : 1 ),txtHomePath;
        var GraphInd=state.rGraphicsTree.findIndex(a => (a.id==intHomeId));
        if(GraphInd!=-1)
            txtHomePath=state.rGraphicsTree[GraphInd].path;
        //console.warn("intHomeId",intHomeId,GraphInd,txtHomePath,sPerms[intGraphInd]);
        if(txtPath=='' && intHomeId!=1) //User wants to go home, but their home is NOT home
        {
            txtPath=txtHomePath;
        }
        var intObjIndex, intIndex=state.rGraphExpPaths.findIndex(cG => cG == txtPath);
        //console.warn("Graph Tree SetGraphPath:",sPayLoad, GraphInd,state.rGraphicsTree);
        //console.warn("Fin txtPath:",intIndex,txtPath);
        if(txtPath=="home" || txtPath=="/home") { //Home request
            state.CurrGraphPath=intHomeId;
            state.CurrGraphPathID=intHomeId;
            state.rGraphExpPaths=[''];
            state.rGraphExpIDs=[intHomeId];
        }
        else if(intIndex==state.rGraphExpIDs.length-1 && state.rGraphExpIDs.length > 1) { //Clicked on the last path in the list
            //console.warn("Toggling off end path...");
            intObjIndex=state.rGraphicsTree.findIndex(cGraph => cGraph?.path == txtPath);
            state.CurrGraphPathID=state.rGraphicsTree[intObjIndex].parent;
            //console.log("Step1 intObjIndex:",intObjIndex);
            //console.log("Step2 parent:",state.rGraphicsTree[intObjIndex].parent);
            //console.log("Step3 path:",state.rGraphicsTree[intObjIndex]);
            intObjIndex=state.rGraphicsTree.findIndex(cGraph => cGraph?.id == state.rGraphicsTree[intObjIndex].parent);
            state.CurrGraphPath=state.rGraphicsTree[intObjIndex].id;  //Move the Curr Path to Parent
            state.rGraphExpPaths.splice(intIndex ,1);
            state.rGraphExpIDs.splice(intIndex ,1);
        }
        else { //Fill variables with tree up to path sent
            //console.warn("Navigating to new endpoint...");
            state.CurrGraphPath=txtPath;
            //console.warn("Looking for:",txtPath,state.rGraphicsTree);
            intObjIndex=state.rGraphicsTree.findIndex(cGraph => cGraph?.path == txtPath);
            if(intObjIndex!=-1) {
                //console.warn("WTF Index?",intObjIndex,state.rGraphicsTree);
                state.CurrGraphPathID=state.rGraphicsTree[intObjIndex].id;
                var y=0,sParent=state.rGraphicsTree.find(cGraph => cGraph?.id === state.rGraphicsTree[intObjIndex].parent);
                //console.log("sParent is now ("+y+"):",intHomeId,state.CurrGraphPathID,sParent);
                state.rGraphExpPaths=[txtPath];
                state.rGraphExpIDs=[state.CurrGraphPathID];
                //console.warn("ExpPAths is correct?",state.rGraphExpPaths);
                if(sParent!=null ) {
                    while(sParent.id!=intHomeId && sParent.parent!=null && y<42) {
                        //console.log("sParent is now ("+y+"):",JSON.stringify(sParent));
                        state.rGraphExpPaths.unshift(sParent.path);
                        state.rGraphExpIDs.unshift(sParent.id);
                        sParent=state.rGraphicsTree.find(cGraph => cGraph?.id === sParent.parent);
                        y++;
                    }
                    //console.warn("ExpPAths is correct still?",state.rGraphExpPaths);
                    state.rGraphExpPaths.unshift('');
                    state.rGraphExpIDs.unshift(intHomeId);
                }
            }
            else {
                state.CurrGraphPathID=intHomeId;
                state.rGraphExpPaths.unshift('');
                state.rGraphExpIDs.unshift(intHomeId);
            }

        }
        //console.log("NewCGraphPath:",state.CurrGraphPath);
        //console.log("NewCGraphPathID:",state.CurrGraphPathID);
        //console.log("New rGraphExpPaths:",state.rGraphExpPaths);
        //console.log("New rGraphExpIDs:",state.rGraphExpIDs);

    },
    SetFridgePath(state, sPayLoad) {
        var txtPath=sPayLoad.txtPath;//, intFridgeInd;
        var sPerms=sPayLoad.UserPerms, intFridgeInd;
        for(y=0;y<sPerms.length;y++) {
            if(sPerms[y].module_id=='fridges' || sPerms[y].module_id=='cold_storage')
                intFridgeInd=y;
        }
        var intHomeId=(intFridgeInd!=-1 ? parseInt(sPerms[intFridgeInd].mod_home) : 1 ),txtHomePath;
        var FridgeInd=state.rFridges.findIndex(f => (f.id==intHomeId));
        if(FridgeInd!=-1)
            txtHomePath=state.rFridges[FridgeInd].path;
        if(txtPath=='' && intHomeId!=1) //User wants to go home, but their home is NOT home
        {
            txtPath=txtHomePath;
        }

        var sNewPath={}, intObjIndex, intIndex=state.rFridgeExpPaths.findIndex(cFridge => cFridge == txtPath);
        //console.log("SetFridgePath can find "+txtPath+" in:",state.rFridgeExpPaths);
        //console.log("FridgePath Test:",intIndex);
        if(txtPath==txtHomePath) { //Home request
            state.CurrFridgePath=txtHomePath;
            state.CurrFridgePathID=intHomeId;
            state.rFridgeExpPaths=[txtHomePath];
            state.rFridgeExpIDs=[intHomeId];
        }
        else if(intIndex==state.rFridgeExpPaths.length-1 && state.rFridgeExpPaths.length>1) {  //Clicked on the last path in the list
            //console.log("Removing path...",txtPath);
            //console.log("Paths:",state.rFridgeExpPaths);
            intObjIndex=state.rFridges.findIndex(cFridge => cFridge?.path == txtPath);
            state.CurrFridgePathID=state.rFridges[intObjIndex].parent;
            //console.log("Step1 intObjIndex:",intObjIndex);
            //console.log("Step2 parent:",state.rFridges[intObjIndex].parent);
            intObjIndex=state.rFridges.findIndex(cFridge => cFridge?.id == state.CurrFridgePathID);
            //console.log("inObjIndex2:",state.CurrFridgePathID,intObjIndex);
            if(intObjIndex!=-1) {
                state.CurrFridgePath=state.rFridges[intObjIndex].path;  //Move the Curr Path to Parent
                
                state.rFridgeExpPaths.splice(intIndex ,1);
                state.rFridgeExpIDs.splice(intIndex ,1);
            }
            else
            {
                state.CurrFridgePath=state.rFridges[0].path;  //Move the Curr Path to Parent
                state.rFridgeExpPaths.splice(1 ,1);
                state.rFridgeExpIDs.splice(1 ,1);
            }
        }
        else {  //Fill variables with tree up to path sent
            state.CurrFridgePath=txtPath;
            sNewPath=state.rFridges.find(cFridge => cFridge?.path === txtPath);
            state.CurrFridgePathID=sNewPath.id;
            var y=0,sParent=state.rFridges.find(cFridge => cFridge?.id === sNewPath.parent);
            //console.log("sParent is now ("+y+"):",JSON.stringify(sParent));
            state.rFridgeExpPaths=[txtPath];
            state.rFridgeExpIDs=[state.CurrFridgePathID];
            //console.log("Entering loop?",sNewPath.id,sNewPath.parent,sPerms);
            while(typeof sParent?.id !='undefined' && sParent.id!=1 && sParent.parent!=null && y<42) {
                //console.log("sParent is now ("+y+"):",JSON.stringify(sParent));
                if(sParent.id!=intHomeId) {
                    state.rFridgeExpPaths.unshift(sParent.path);
                    state.rFridgeExpIDs.unshift(sParent.id);
                    sParent=state.rFridges.find(cFridge => cFridge?.id === sParent.parent);
                }
                y++;
            }
            state.rFridgeExpPaths.unshift(txtHomePath);
            state.rFridgeExpIDs.unshift(intHomeId);
        }
        //console.warn("NewCFridgePath:",state.CurrFridgePath);
        //console.warn("NewCFridgePathID:",state.CurrFridgePathID);
        //console.warn("New rFridgeExpPaths:",state.rFridgeExpPaths);
        //console.warn("New rFridgeExpIDs:",state.rFridgeExpIDs);
    },
    SetHistPath(state, sPayLoad) {
        var txtPath=sPayLoad.txtPath;//, intHistInd;
        var sPerms=sPayLoad.UserPerms, intHistInd;
        for(y=0;y<sPerms.length;y++) {
            if(sPerms[y].module_id=='history')
                intHistInd=y;
        }
        var intHomeId=(intHistInd!=-1 ? parseInt(sPerms[intHistInd].mod_home) : 1 ),txtHomePath='';
        var HistInd=state.rHistTree.findIndex(a => (a.id==intHomeId));
        if(HistInd!=-1)
            txtHomePath=state.rHistTree[HistInd].path;
        if(txtPath=='' && intHomeId!=1) //User wants to go home, but their home is NOT home
        {
            txtPath=txtHomePath;
        }

        var sNewPath={}, intObjIndex, intIndex=state.rHistExpPaths.findIndex(cHist => cHist == txtPath);
        //console.log("SetHistPath can find "+txtPath+" in:",state.rHistExpPaths);
        //console.log("HistPath Test:",intIndex);
        if(txtPath==txtHomePath) { //Home request
            //console.warn('Alarms-> Home Req:',txtPath,txtHomePath);
            state.CurrHistPath=txtHomePath;
            state.CurrHistPathID=intHomeId;
            state.rHistExpPaths=[txtHomePath];
            state.rHistExpIDs=[intHomeId];
        }
        else if(intIndex==state.rHistExpPaths.length-1 && state.rHistExpPaths.length>1) {  //Clicked on the last path in the list
            //console.warn("Alarms->Removing path...",txtPath);
            //console.log("Paths:",state.rHistExpPaths);
            intObjIndex=state.rHistTree.findIndex(cHist => cHist?.folder_path == txtPath);
            state.CurrHistPathID=state.rHistTree[intObjIndex].parent_id;
            //console.log("Step1 intObjIndex:",intObjIndex);
            //console.log("Step2 parent:",state.rHistTree[intObjIndex].parent);
            intObjIndex=state.rHistTree.findIndex(cHist => cHist?.location_id == state.CurrHistPathID);
            //console.log("inObjIndex2:",state.CurrHistPathID,intObjIndex);
            if(intObjIndex!=-1) {
                state.CurrHistPath=state.rHistTree[intObjIndex].folder_path;  //Move the Curr Path to Parent
                state.rHistExpPaths.splice(intIndex ,1);
                state.rHistExpIDs.splice(intIndex ,1);
            }
            else
            {
                state.CurrHistPath=state.rHistTree[0].folder_path;  //Move the Curr Path to Home
                state.rHistExpPaths.splice(1 ,1);
                state.rHistExpIDs.splice(1 ,1);
            }
        }
        else {  //Fill variables with tree up to path sent
            //console.warn("Hist Nav Down to:",txtPath);
            state.CurrHistPath=txtPath;
            sNewPath=state.rHistTree.find(cHist => cHist?.folder_path === txtPath);
            state.CurrHistPathID=sNewPath.location_id;
            var y=0,sParent=state.rHistTree.find(cHist => cHist?.id === sNewPath.parent);
            //console.log("sParent is now ("+y+"):",JSON.stringify(sParent));
            state.rHistExpPaths=[txtPath];
            state.rHistExpIDs=[state.CurrHistPathID];
            //console.log("Entering loop?",sNewPath.id,sNewPath.parent,sPerms);
            while(typeof sParent?.location_id !='undefined' && sParent.location_id!=1 && sParent.parent_id!=null && y<42) {
                //console.log("sParent is now ("+y+"):",JSON.stringify(sParent));
                if(sParent.location_id!=intHomeId) {
                    state.rHistExpPaths.unshift(sParent.folder_path);
                    state.rHistExpIDs.unshift(sParent.location_id);
                    sParent=state.rHistTree.find(cHist => cHist?.id === sParent.parent_id);
                }
                y++;
            }
            state.rHistExpPaths.unshift(txtHomePath);
            state.rHistExpIDs.unshift(intHomeId);
        }
        state.intFRefresh++;
        //console.warn("NewCHistPath:",state.CurrHistPath);
        //console.warn("NewCHistPathID:",state.CurrHistPathID);
        //console.warn("New rAlarmExpPaths:",state.rHistExpPaths);
        //console.warn("New rAlarmExpIDs:",state.rHistExpIDs);
    },
    SetAlarmPath(state, sPayLoad) {
        var txtPath=sPayLoad.txtPath;//, intAlarmInd;
        var sPerms=sPayLoad.UserPerms, intAlarmInd;
        for(y=0;y<sPerms.length;y++) {
            if(sPerms[y].module_id=='alarms')
                intAlarmInd=y;
        }
        var intHomeId=(intAlarmInd!=-1 ? parseInt(sPerms[intAlarmInd].mod_home) : 1 ),txtHomePath;
        var AlarmInd=state.rAlarms.findIndex(a => (a.id==intHomeId));
        if(AlarmInd!=-1)
            txtHomePath=state.rAlarms[AlarmInd].path;
        if(txtPath=='' && intHomeId!=1) //User wants to go home, but their home is NOT home
        {
            txtPath=txtHomePath;
        }

        var sNewPath={}, intObjIndex, intIndex=state.rAlarmExpPaths.findIndex(cAlarm => cAlarm == txtPath);
        //console.log("SetAlarmPath can find "+txtPath+" in:",state.rAlarmExpPaths);
        //console.log("AlarmPath Test:",intIndex);
        if(txtPath==txtHomePath) { //Home request
            //console.warn('Alarms-> Home Req:',txtPath,txtHomePath);
            state.CurrAlarmPath=txtHomePath;
            state.CurrAlarmPathID=intHomeId;
            state.rAlarmExpPaths=[txtHomePath];
            state.rAlarmExpIDs=[intHomeId];
        }
        else if(intIndex==state.rAlarmExpPaths.length-1 && state.rAlarmExpPaths.length>1) {  //Clicked on the last path in the list
            //console.warn("Alarms->Removing path...",txtPath);
            //console.log("Paths:",state.rAlarmExpPaths);
            intObjIndex=state.rAlarms.findIndex(cAlarm => cAlarm?.folder_path == txtPath);
            state.CurrAlarmPathID=state.rAlarms[intObjIndex].parent;
            //console.log("Step1 intObjIndex:",intObjIndex);
            //console.log("Step2 parent:",state.rAlarms[intObjIndex].parent);
            intObjIndex=state.rAlarms.findIndex(cAlarm => cAlarm?.id == state.CurrAlarmPathID);
            console.log("inObjIndex2:",state.CurrAlarmPathID,intObjIndex);
            if(intObjIndex!=-1) {
                state.CurrAlarmPath=state.rAlarms[intObjIndex].folder_path;  //Move the Curr Path to Parent
                state.rAlarmExpPaths.splice(intIndex ,1);
                state.rAlarmExpIDs.splice(intIndex ,1);
            }
            else
            {
                state.CurrAlarmPath=state.rAlarms[0].folder_path;  //Move the Curr Path to Home
                state.rAlarmExpPaths.splice(1 ,1);
                state.rAlarmExpIDs.splice(1 ,1);
            }
        }
        else {  //Fill variables with tree up to path sent
            console.warn("Alarm Nav Down.",txtPath);
            state.CurrAlarmPath=txtPath;
            sNewPath=state.rAlarms.find(cAlarm => cAlarm?.folder_path === txtPath);
            console.warn('sNewPath:',sNewPath,txtPath,state.rAlarms);
            if(typeof sNewPath!='undefined') {
                state.CurrAlarmPathID=sNewPath.id;
                var y=0,sParent=state.rAlarms.find(cAlarm => cAlarm?.id === sNewPath.parent);
                //console.log("sParent is now ("+y+"):",JSON.stringify(sParent));
                state.rAlarmExpPaths=[txtPath];
                state.rAlarmExpIDs=[state.CurrAlarmPathID];
                //console.log("Entering loop?",sNewPath.id,sNewPath.parent,sPerms);
                while(typeof sParent?.id !='undefined' && sParent.id!=1 && sParent.parent!=null && y<42) {
                    //console.log("sParent is now ("+y+"):",JSON.stringify(sParent));
                    if(sParent.id!=intHomeId) {
                        state.rAlarmExpPaths.unshift(sParent.folder_path);
                        state.rAlarmExpIDs.unshift(sParent.id);
                        sParent=state.rAlarms.find(cAlarm => cAlarm?.id === sParent.parent);
                    }
                    y++;
                }
                state.rAlarmExpPaths.unshift(txtHomePath);
                state.rAlarmExpIDs.unshift(intHomeId);
            }
        }
        state.intFRefresh++;
        //console.warn("NewCAlarmPath:",state.CurrAlarmPath);
        //console.warn("NewCAlarmPathID:",state.CurrAlarmPathID);
        //console.warn("New rAlarmExpPaths:",state.rAlarmExpPaths);
        //console.warn("New rAlarmExpIDs:",state.rAlarmExpIDs);
    },
    SetAlarmSearch(state,strSearch) {
        state.strAlarmSearch=strSearch;
        //console.log("SEARCH FOR:",state.strFridgeSearch,strSearch);
    },
    SetFridgeSearch(state,strSearch) {
        state.strFridgeSearch=strSearch;
        //console.log("SEARCH FOR:",state.strFridgeSearch,strSearch);
    },
    SetUserSearch(state,strSearch) {
        state.strUserSearch=strSearch;
        //console.log("SEARCH FOR:",state.strFridgeSearch,strSearch);
    },
    GetUsers(state) {
        state.bFetchingData=true;
        apiCall.post(hostForAPICalls+'/plugins/useradmin/getAllUsers.php')
        .then(response => 
        {
            console.log("User Tree:",response);
            state.rUsers=response.data.response;
            state.bFetchingData=false;
        })
        .catch(function (error) {
            console.log("ERROR:",error);
            state.bFetchingData=false;
        });
    },
    FetchControllers(state) {
        //var ContReq = new FormData();
        //ContReq.set('')
        apiCall.post(hostForAPICalls+'/plugins/controllers/php/query_location_tree.php')
        .then(response => 
        {
            //console.log("Response:",response);
            state.dtmControllerPoll=new Date();
            state.rControllers=response.data.controllers;
            state.rLocations=response.data.locations;
            state.rScreens=response.data.screens;
            //console.log("dtmControllerPoll:",state.dtmControllerPoll);
            //console.log("Controllers:",state.rControllers);
            state.intFRefresh++;
        })
        .catch(function (error) {
            console.log("ERROR:",error);
        });

    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};