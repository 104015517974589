const routes = [
  /*{
    path: '/Signature',
    name: 'ConsentSig',
    component: () => import('../views/PatientSig.vue'),
    params: {

    }
  },
  {
    path: '/journey',
    name: 'LandingPage',
    component: () => import('../views/LandingPage.vue'),
    params: {

    }
  },*/
  {
    path: '/p/consent-request/:id',
    name: 'LandingPage2',
    component: () => import('../views/LandingPage.vue'),
    params: {

    }
  },
  {
    path: '/journey/:id',
    name: 'Journey',
    component: () => import('../views/LandingPage.vue'),
    params: {

    }
  },
]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
    routes.push({
      path: '/:catchAll(.*)',
      component: () => import('../views/LandingPage.vue')
    })
  }
  
export default routes  