import { createRouter, createWebHistory } from 'vue-router'
//import { store } from '../store'
import routes from './routes'


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

/*router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    //const loggedIn = localStorage.getItem('user');
    const publicPages = ['/login'];
    var loggedIn = store.getters["users/getAuthUser"]; //Put this back!
    var strPath=to.path.toString();
    const authRequired = !publicPages.includes(to.path);
    //console.log("Am I here?",strPath,strPath.substring(0,6),loggedIn,JSON.stringify(to),from,next,authRequired,routes);
    //console.log("strPath?",strPath,strPath.substring(0,6),loggedIn,JSON.stringify(to),from,next,authRequired,routes);
  
    if (authRequired && strPath.substring(0,6)!='/login' && !loggedIn) {
      console.log("User not logged in. Passing:",to.query.username,to.query.password, to.query);
      //return router.push('/login');
      if(to.path=='/index.html')
        router.push({name:'login',params: {username:to?.query?.username, password:to?.query?.password}});
      else 
        router.push({name:'login'});
    }
    next();
});*/

export default router
